@use 'sass:math';

// ------------------------------------------------------------
// Navigation
// ------------------------------------------------------------

.navigation-primary {
	display: block;
	@include respond-to-max($vpMenu) {
		overflow: hidden;
	}
	@include respond-to($vpMenu) {
		display: inline-block;
		position: absolute;
		bottom: 14px;
		right: 0;
		left: 0;
		z-index: 1;
	}
}

.navigation-primary-toggle {
	position: absolute;
	top: 7px;
	right: $internal-spacing;
	width: 65px;
	height: 44px;
	display: inline-block;
	text-align: left;
	cursor: pointer;
	padding: 0 0 0 0;
	font-size: rem($typeMe);
	line-height: 44px;
	color: $black;
	&:before, &:after {
		content: "";
		position: absolute;
		height: 2px;
		width: 16px;
		background-color: $grasslands_20;
		transition: all, ease-in-out, 0.3s;
	}
	&:before {
		top: 15px;
		right: 0;
	}
	&:after {
		bottom: 15px;
		right: 8px;
		width: 8px;
	}
	span:after {
		content: "";
		position: absolute;
		height: 2px;
		width: 16px;
		background-color: $grasslands_20;
		transition: all, ease-in-out, 0.3s;
		right: 0;
		top: 21px;
	}
	@include respond-to($vpMenu) {
		display: none;
	}
	&--open {
		span:after {
			background-color: transparent;
		}
		&:before {
			transform: rotate(45deg);
			width: 20px;
			top: 21px;
			right: 0;
		}
		&:after {
			transform: rotate(-45deg);
			width: 20px;
			bottom: 21px;
			right: 0;
		}
	}
}

.navigation-primary__items {
	transition: all, ease-in-out, 0.3s;
	position: relative;
	top: 10px;
	&:before {
		transition: all, ease-in-out, 0.3s;
		content: "";
		height: 1px;
		background-color: $limestone_40;
		position: relative;
		display: block;
		z-index: 10;
		opacity: 0;
	}
	@include respond-to-max($vpMenu) {
		html.js & {
			max-height: 0;
			overflow: hidden;
		}
	}
	@include respond-to($vpMenu) {
		padding-right: 10px;
		display: flex;
		justify-content: flex-end;
		align-items: stretch;
		flex-wrap: nowrap;
		width: 100%;
	}
	&--open {
		html.js & {
			max-height: 100%;
			width: 100%;
			left: 0;
		}
		&:before {
			opacity: 1;
		}
	}
}

.navigation-primary__item {
	@include pie-clearfix;
	display: block;
	font-size: rem($typeMe);
	@include respond-to($vpD) {
		font-size: rem($typeM);
	}
	line-height: 1.5;
	z-index: 1;
	@include link-active-styles {
		> .navigation-primary__multinav-trigger {
			background-color: $grasslands_30;
		}
	}
	a {
		color: $black;
		padding: $internal-spacing*.65 $internal-spacing;
		padding-right: $internal-spacing*2.5;
		transition: all ease-in-out, 0.2s;
		background-color: transparent;
		display: block;
		position: relative;
		border-bottom: 1px solid $limestone_40;
		@include link-active-styles {
			background-color: $grasslands_40;
		}
		@include respond-to($vpMenu) {
			text-align: center;
			padding: $internal-spacing*.3 $internal-spacing*.5;
			border-bottom: 0;
		}
		@include respond-to($vpE) {
			padding: $internal-spacing*.3 $internal-spacing;
		}
	}
	@include respond-to($vpMenu) {
		a {
			border-radius: $internal-spacing;
			@include link-active-styles {
				background-color: $grasslands_40;
			}
		}
		.navigation-primary__item a {
			border-radius: 0;
			@include link-active-styles {
				background-color: transparent;
			}
		}
	}
	&--active,
	&.current-page-ancestor,
	&.current-menu-ancestor,
	&.current-menu-item,
	&.current_page_parent
	 {
		> a {
			background-color: $grasslands_40;
			border-bottom: 1px solid $heathlands_40;
			@include respond-to($vpMenu) {
				 background-color: transparent;
				 border-bottom: 0;
			}
		}
		@include respond-to($vpMenu) {
			text-indent: 0;
			> a {
				background-color: $grasslands_40;
			}
		}
	}
	&--ss-only {
		@include respond-to($vpMenu) {
			display: none;
		}
	}
	@include respond-to-max($vpMenu) {
		// .open {
		// 	background-color: $limestone_60;
		// 	.open {
		// 		background-color: $limestone_50;
		// 	}
		// }
	}
}

// ------------------------------------------------------------
// Secondary Navigation
// ------------------------------------------------------------

.navigation-secondary {
	display: none;
	@include respond-to($vpMenu) {
		display: inline-block;
		float: right;
	}
}

.navigation-secondary__item {
	font-size: rem($typeDefault);
	float: left;
	display: inline-block;
	position: relative;
	a {
		padding: 4px $internal-spacing*.5 4px $internal-spacing*.5;
		display: inline-block;
		transition: all, ease-in-out, 0.3s;
		border-bottom: 0;
		border-radius: $internal-spacing;
		@include respond-to($vpE) {
			padding: 4px $internal-spacing 4px $internal-spacing*.75;
		}
		svg {
			width: 22px;
			height: 22px;
			display: inline;
			color: $grasslands_20;
			position: relative;
			top: -2px;
			margin-right: 2px;
			&.active {
				display: none;
			}
		}
		@include link-active-styles {
			background-color: $grasslands_40;
			svg {
				display: none;
			}
			svg.active {
				display: inline;
			}
		}
	}
	@include respond-to-max($vpMenu) {
		&--motif a {
			line-height: 34px;
		}
	}
	&--active,
	&.current-page-ancestor,
	&.current-menu-ancestor,
	&.current-menu-item,
	&.current_page_parent
	 {
		position: relative;
		> a {
			@include respond-to($vpMenu) {
				 background-color: transparent;
				 border-bottom: 0;
			}
		}
		@include respond-to($vpMenu) {
			text-indent: 0;
			> a {
				background-color: $grasslands_40;
				svg {
					display: none;
				}
				svg.active {
					display: inline;
				}
			}
		}
	}
}

// ------------------------------------------------------------
// Mobile multi navigation
// ------------------------------------------------------------

.nav-grid {
	@include respond-to($vpMenu) {
		padding-top: 14px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		grid-gap: $internal-spacing*.5;
	}
	&__section {
		position: relative;
		&:first-child {
			&:before {
				background-color: transparent;
			}
		}
		&--triple {
			grid-column: span 3;
			padding: $internal-spacing*.75 0;
			@include respond-to($vpMenu) {
				padding-left: 14px;
			}
		}
		&__inner-grid {
			display: grid;
			gap: 14px;
			grid-template-columns: repeat(1, 1fr);
			@include respond-to($vpAA) {
				grid-template-columns: repeat(2, 1fr);
			}
			@include respond-to($vpB) {
				grid-template-columns: repeat(3, 1fr);
			}
		}
		&__heading {
			display: none;
			@include respond-to($vpMenu) {
				display: block;
				color: $limestone_20;
				padding: $internal-spacing*.5 0 $internal-spacing*.75;
				margin-bottom: $internal-spacing*.25;
				font-family: $fontHeading;
				border-bottom: $limestone_40 solid 1px;
			}
		}
		> .navigation-primary__item {
			@include respond-to($vpMenu) {
				// Border dashes control: https://jsfiddle.net/yyp67pbg/
				background-image: linear-gradient(to right, $limestone_40 33%, rgba(255,255,255,0) 0%);
				background-position: bottom;
				background-size: 8px 1px;
				background-repeat: repeat-x;
				padding-bottom: $internal-spacing*.5;
				margin-bottom: $internal-spacing*.5;
				&:last-child {
					background-image: none;
					padding-bottom: 0;
					margin-bottom: 0;
				}
			}
		}
	}
}

.navigation-primary__multinav,
.navigation-secondary__multinav {
	display: none;
	@include respond-to-max($vpMenu) {
		.navigation-primary__item a {
			padding-left: $internal-spacing*1;
		}
		.navigation-primary__item .navigation-primary__item a {
			padding-left: $internal-spacing*2;
		}
		.navigation-primary__item .navigation-primary__item .navigation-primary__item a {
			padding-left: $internal-spacing*3;
		}
		.navigation-primary__item .navigation-primary__item .navigation-primary__item .navigation-primary__item a {
			padding-left: $internal-spacing*4;
		}
		.navigation-primary__item .navigation-primary__item .navigation-primary__item .navigation-primary__item .navigation-primary__item a {
			padding-left: $internal-spacing*5;
		}
		.navigation-primary__item .navigation-primary__item .navigation-primary__item .navigation-primary__item .navigation-primary__item .navigation-primary__item a {
			padding-left: $internal-spacing*6;
		}
	}
	.navigation-primary__item,
	.navigation-secondary__item {
		display: block;
		float: none;
		border-left: 0;
		a {
			display: block;
			svg {
				display: inline-block;
				float: left;
				margin-right: 2px;
				height: 24px;
				width: 24px;
				color: $grasslands_20;
			}
		}
		ul {
			@include respond-to-max($vpMenu) {
				display: none;
			}
			.navigation-primary__item,
			.navigation-secondary__item {
				&:last-child {
					// border-bottom: none;
				}
			}
			> ul {
				border-bottom: 1px dashed $limestone_40;
			}
		}
	}
	@include respond-to($vpMenu) {
		position: absolute;
		left: -1px;
		right: -1px;
		padding: 14px;
		width: calc(100% + 2px);
		background-color: $white;
		box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.2);
		.header-wrap--wardens & {
			background-color: $limestone_60;
			box-shadow: 0;
		}
		z-index: 1;
		border-left: 1px solid $limestone_40;
		border-left: 1px solid $limestone_40;
		border-right: 1px solid $limestone_40;
		border-bottom: 1px solid $limestone_40;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		&:before {
			content: "";
			height: 1px;
			background-color: $limestone_40;
			position: relative;
			display: block;
			z-index: 10;
		}
		.navigation-primary__item,
		.navigation-secondary__item {
			&:before {
				content: "";
				display: block;
				margin: 0 $internal-spacing;
				height: 1px;
			}
			&:first-child:before {
				display: none;
			}
			a {
				text-align: left;
				padding: $internal-spacing*.4 0;
				height: auto;
				font-family: $fontHeading;
				font-size: rem($typeM);
				color: $woodlands_20;
				@include link-active-styles {
					color: $woodlands_10;
					text-decoration: underline;
				}
				svg {
					display: none;
				}
			}
			ul {
				.navigation-primary__item {
					&:first-child:before {
						display: block;
					}
					a {
						color: $black;
						font-size: rem($typeMe);
						font-family: $fontDefault;
					}
					.navigation-primary__item a {
						svg {
							display: inline-block;
						}
					}
					.navigation-primary__item ul .navigation-primary__item a {
						padding-left: $internal-spacing*1;
					}
					.navigation-primary__item ul .navigation-primary__item ul .navigation-primary__item a {
						padding-left: $internal-spacing*2;
					}
				}
			}
		}
	}
}

.navigation-primary__item {
	@include respond-to-max($vpMenu) {
		position: relative;
	}
	@include respond-to($vpMenu) {
		&:hover,
        &:focus,
        &:focus-within {
			.navigation-primary__multinav {
				display: block;
				z-index: 99;
			}
		}
	}
}

.navigation-primary__multinav-trigger {
	position: absolute;
	z-index: 99;
	right: 0;
	top: 0;
	display: block;
	height: 50px;
	width: 50px;
	cursor: pointer;
	background-color: $limestone_60;
	&:after {
		position: absolute;
		top: 9px;
		left: 9px;
		width: 32px;
		height: 32px;
		display: block;
		background-image: url('../icons/source/arrow-right.svg');
		background-repeat: no-repeat;
		background-size: cover;
		content: '';
	}
	&--active {
		border-color: $heathlands_40;
		&:after {
			background-image: url('../icons/source/arrow-down.svg');
		}
	}
	@include respond-to($vpMenu) {
		display: none;
	}
	@include link-active-styles {
		background: $grasslands_30;
	}
	span {
		@include a11yhide;
	}
}

// Navigation jump

.navigation-jump,
.navigation-sidebar,
.linklist {
	margin-bottom: $internal-spacing*2;
	&__item {
		border-top: 1px solid $limestone_40;
		a {
			display: block;
			position: relative;
			font-size: rem($typeM);
			border-bottom: 0;
			line-height: 1.5;
			padding: $internal-spacing*.75 0;
			overflow: hidden;
			transition: all, ease-in-out, 0.3s;
			svg {
				color: $grasslands_20;
				height: 24px;
				width: 24px;
				position: absolute;
				left: -24px;
				top: 18px;
				transition: all, ease-in-out, 0.3s;
			}
			@include link-active-styles {
				border-bottom: 0;
				padding-left: $internal-spacing*1.5;
				font-family: $fontHeading;
				svg {
					left: 0;
				}
			}
		}
		&--active > a {
			font-family: $fontHeading;
		}
	}
}

// Sidebar {
.navigation-sidebar {
	&__item__children {
		a {
			padding-left: $internal-spacing*1.5;
			svg {
				left: 0;
				// right: 0;
			}
			@include link-active-styles {
				// padding-left: 0;
				svg {
					// left: auto;
				}
			}
		}
	}
}

.linklist {
	&__items {
		list-style: none;
	}
	&__item {
		border-top: 0;
		a {
			padding-left: $internal-spacing*1.5;
			color: $white;
			svg {
				left: 0;
				color: $grasslands_40;
			}
		}
	}
}
