// Modal
// ------------------------------------------------------------


.modal {
	display: none;
	visibility: hidden;
	position: fixed;
	z-index: 99;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
	&--show {
		display: block;
		visibility: visible;
	}
}

.modal__content {
	background-color: $white;
	margin: 15% auto;
	border-radius: $border-radius;
	position: relative;
	display: flex;
	overflow: hidden;
	z-index: 100;
	width: 90%;
	@include respond-to($vpC) {
		width: 60%;
	}
}

.modal__header {
	padding: $internal-spacing*1.5;
	background-color: $limestone_60;
	flex: 1 1 0;
}

.modal__title {
	font-size: rem($typeL);
	font-family: $fontHeading;
}

.modal__body {
	padding: $internal-spacing*1.5;
	flex: 2 1 0;
	dl {
		list-style: none;
		margin-block-start: 0;
	}
	dt {
		font-size: rem($typeMe);
		font-family: $fontHeading;
	}
	dd {
		font-size: rem($typeMe);
		margin-inline-start: 0;
		margin-bottom: $internal-spacing;
	}
}

.modal__close {
	width: 40px;
	height: 40px;
	svg {
		width: 40px;
		height: 40px;
	}
	position: absolute;
	top: $internal-spacing*.5;
	right: $internal-spacing*.5;
	z-index: 5;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}