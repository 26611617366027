@use 'sass:math';

.downloads {
	position: relative;
	border-radius: $border-radius;
	overflow: hidden;
}

.downloads__heading {
	@extend .heading;
	font-size: rem($typeXL);
	margin-bottom: math.div($internal-spacing,2);
	line-height: 1.3;
	transition: all, ease-in-out, 0.3s;
}

.downloads__excerpt {
	font-size: rem($typeDefault);
	margin-bottom: $internal-spacing;
}

.downloads__meta {
	position: relative;
	font-size: rem($typeDefault);
	list-style: none;
	margin-bottom: $internal-spacing;
	li {
		margin-bottom: 5px;
		svg {
			width: 24px;
			height: 24px;
			vertical-align: text-baseline;
			margin: 0 10px 0 0;
			display: inline-block;
			stroke: $grasslands_20;
			stroke-width: 2px;
		}
		a {
			text-decoration: none;
			border-color: $grasslands_20;
			@include link-active-styles {
				border-color: $grasslands_10;
			}
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
}