.filter {
    background-color: $limestone_60;
	padding: $internal-spacing;
	border-radius: $border-radius;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
	@include respond-to-max($vpC) {
		margin-bottom: $internal-spacing*2;
	}
	&--events {
		margin-bottom: $internal-spacing*2;
	}
}

.filter__heading {
    @extend .heading;
    position: relative;
    font-size: rem($typeXL);
    outline: none;
    width: 100%;
    display: block;
    cursor: pointer;
    line-height: $internal-spacing*3;
    @include respond-to-max($vpC) {
    	line-height: $internal-spacing*2;
    }
    svg {
        position: absolute;
        right: 0;
        top: 0;
        width: $internal-spacing*2;
        height: $internal-spacing*2;
        transition: all, ease-in-out, 0.3s;
        color: $grasslands_20;
        @include respond-to($vpC) {
			display: none;
        }
    }
    &--open {
    }
}

.filter__reveal {
    @include respond-to-max($vpC) {
    	overflow: hidden;
        html.js & {
            transition: all, ease-in-out, 0.25s;
            max-height: 0;
        }
        &--open {
            html.js & {
                margin-top: $internal-spacing;
                max-height: none;
            }
        }
    }
}

.filter__form {
    .form-field {
    	border-top: 1px solid $limestone_40;
    	padding: $internal-spacing 0;
    	&:last-child {
    		padding-bottom: 0;
    	}
        // width: 23%;
        // margin-left: 1%;
        // margin-bottom: 1em;
        // float: left;
        // display: inline-block;
        // @supports (display: -ms-grid) {
        //     width: 23%;
        //     float: left;
        //     &--thin {
        //         width: 16%;
        //     }
        // }
        // @supports (display: grid) {
        // 	width: auto;
        //     margin-left: 0;
        //     margin-bottom: 0;
        //     float: none;
        // 	grid-column: span 3;
        //     &--thin {
        //     	width: auto;
        //         grid-column: span 2;
        //     }
        // }
    }

	.filter--news & {

		@include respond-to($vpC) {
	    	.form-field {
	        	// @supports (display: grid) {
	            // 	grid-column: span 2;
		        //     &:first-child,
		        //     &:last-child {
		        //         grid-column: span 1;
		        //     }
		        // }
		    }
	    }
	}
}