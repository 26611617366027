// ------------------------------------------------------------
// Scroll Top
// ------------------------------------------------------------

.scroll-top {
	width: 50px;
	height: 50px;
	position: fixed;
	bottom: $internal-spacing;
	right: $internal-spacing;
	background-color: $grasslands_30;
	color: $cHeading;
	border-radius: 25px;
	z-index: 999;
	background-image: url(/assets/icons/source/chevron-up.svg);
	background-size: cover;
	background-position: bottom center;
	display: none;
	span {
		@include a11yhide;
	}
	@include link-active-styles {
		background-color: lighten($grasslands_30,5%);
		cursor: pointer;
	}
}