.pagination {
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: $internal-spacing*3;
    .pagination__item-link {
        font-size: rem($typeMe);
    }
}

.pagination__item {
    font-size: rem($typeMe);
    display: inline-block;
    display: none;
    @include respond-to($vpC) {
        display: block;
    }
}

.pagination__item-ellipsis {
	color: $limestone_20;
	font-weight: $fontSemiBold;
	margin: 0 5px;
}

.pagination__item-link {
	color: $black;
    display: block;
    line-height: $internal-spacing*2;
    width: $internal-spacing*2;
    text-align: center;
    margin: 0 5px;
}

a.pagination__item-link {
    transition: all .3s ease-in-out;
    border-bottom: 0;
    border-radius: $internal-spacing;
    &:hover {
    	border-bottom: 0;
        background-color: $limestone_50;
    }
}

.pagination__item-link--active {
    font-weight: $fontSemiBold;
    background-color: $limestone_50;
}

.pagination__item--prev,
.pagination__item--next {
	display: block;
	position: relative;
   	span {
   		position: absolute;
   		left: -9999px;
   	}
   	svg {
   		width: 30px;
   		height: 30px;
   		margin-top: 4px;
   		color: $grasslands_20;
   		// stroke-width: 1.5px;
   	}
}

.pagination__item--prev {
    margin-right: $internal-spacing*.5;
    svg {
    	margin-right: 3px;
    }
}
.pagination__item--next {
    margin-left: $internal-spacing*.5;
    svg {
    	margin-left: 3px;
    }
}

.pagination__item-prev-link,
.pagination__item-next-link {
    height: $internal-spacing*2;
    width: $internal-spacing*2;
    text-align: center;
    transition: all 0.3s ease-in-out;
    border-radius: $internal-spacing;
    border-bottom: 0;
    display: block;
    border: 1px solid $limestone_40;
    &:hover {
        background-color: $limestone_50;
        border-bottom: 0;
        border-color: $limestone_40;
        svg {
        	// stroke: $white;
        }
    }
}
