@use 'sass:math';

.card {
	position: relative;
	background-color: $white;
	border: 1px solid $limestone_40;
	display: block;
	border-radius: $border-radius;
	overflow: hidden;
	.grid__col & {
		margin-top: $internal-spacing;
	}
	&--horizontal,
	&--lion {
		display: flex;
		overflow: visible;
	}
	&--grey {
		background-color: $limestone_60;
	}
	&--route {
		background-color: $limestone_60;
		border: 0;
		@include respond-to($vpC) {
			display: flex;
			overflow: visible;
			// padding-left: 290px;
			&.card--thinner {
				// padding-left: 200px;
			}
		}
	}
	&--route--light {
		background-color: $white;
	}
	&--person {
		background-color: $limestone_60;
		border: 0;
		overflow: hidden;
		display: flex;
		align-items: flex-start;
		.grid-3up & {
			@include respond-to($vpB) {
				margin-bottom: 0;
			}
		}
	}
	&--news {
		border: 1px solid $moors_40;
		background-color: $moors_20;
		border: 0;
		color: $white;
	}
	&--warden-news {
		background-color: $grasslands_10;
		border: 0;
		color: $white;
	}
	&--event {
		background-color: $grasslands_10;
		border: 0;
		color: $white;
	}
	&--light {
		background-color: $white;
		border: 1px solid $skies_40;
	}
	&--links {
		background-color: $limestone_60;
		border: 0;
	}
	&--icon {
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: 90px;
		min-height: 140px;
		padding-bottom: 50px;
		@include respond-to($vpC) {
			min-height: 240px;
			padding-bottom: 100px;
			background-size: auto;
		}
		border: 0;
		.nav-grid & {
			min-height: 200px;
			padding-bottom: 60px;
			text-align: left;
			padding: 0;
			border-bottom: 0;
		}
	}
	&--signpost,
	&--info {
		background-position-x: 85%;
	}
	&--planning {
		background-color: $sands_40;
		border-color: $sands_20;
	}
}

a.card {
	transition: all, ease-in-out, 0.3s;
	@include link-active-styles {
		background-color: $limestone_60;
		border-bottom: 1px solid $limestone_40;
	}
	&--news {
		@include link-active-styles {
			background-color: $moors_10;
			border-bottom: 0;
		}
	}
	&--warden-news {
		@include link-active-styles {
			background-color: $woodlands_10;
			border-bottom: 0;
		}
	}
	&--route {
		@include link-active-styles {
			background-color: $limestone_50;
			border-bottom: 0;
		}
	}
	&--event {
		@include link-active-styles {
			background-color: $grasslands_20;
			border-bottom: 0;
		}
	}
	&--footprint {
		background-color: $grasslands_40!important;
		@include link-active-styles {
			background-color: $grasslands_30!important;
			border-bottom: 0;
		}
	}
	&--signpost {
		background-color: $ocean_rivers_40!important;
		@include link-active-styles {
			background-color: darken($ocean_rivers_40, 7%)!important;
			border-bottom: 0;
		}
	}
	&--horse {
		background-color: $sands_40!important;
		@include link-active-styles {
			background-color: darken($sands_40, 7%)!important;
			border-bottom: 0;
		}
	}
	&--landscape {
		background-color: $woodlands_40!important;
		@include link-active-styles {
			background-color: darken($woodlands_40, 7%)!important;
			border-bottom: 0;
		}
	}
	&--national-trail {
		background-color: $sands_40!important;
		@include link-active-styles {
			background-color: darken($sands_40, 7%)!important;
			border-bottom: 0;
		}
	}
	&--bike {
		background-color: $moors_40!important;
		@include link-active-styles {
			background-color: darken($moors_40, 7%)!important;
			border-bottom: 0;
		}
	}
	&--info {
		background-color: $grasslands_40;
		@include link-active-styles {
			background-color: $grasslands_30;
			border-bottom: 0;
		}
	}
	&--map {
		background-color: $moors_40;
		@include link-active-styles {
			background-color: darken($moors_40, 7%);
			border-bottom: 0;
		}
	}
	&--planning {
		background-color: $sands_40;
		@include link-active-styles {
			background-color: darken($sands_40, 7%);
			border-color: $sands_20;
		}
	}
}

.card__media {
	display: block;
	aspect-ratio: 4 / 3;
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;
	position: relative;
	width: 100%;
	img {
		aspect-ratio: 4 / 3;
		width: 100%;
		max-width: 100%;
	}
	.card--horizontal & {
		// min-height: 120px;
		// min-width: 120px;
		border-radius: $border-radius;
		aspect-ratio: auto;
		@include respond-to($vpC) {
			// width: 30%;
			// min-width: auto;
		}
	// 	@include respond-to-max($vpC) {
	// 		.aside__list & {
	// 			width: 15%;
	// 			min-width: 80px;
	// 		}
	// 	}
	}
	.card--lion & {
		border-radius: $border-radius;
		display: inline-block;
		min-width: auto;
		min-height: auto;
		aspect-ratio: auto;
		width: 190px;
		height: 270px;
		img {
			aspect-ratio: auto;
			width: auto;
			max-width: none;
		}
	}
	.card--route & {
		background-size: cover;
		background-repeat: 0;
		background-position: center;
		aspect-ratio: auto;
		@include respond-to($vpC) {
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
			border-top-right-radius: 0;
			display: block;
			min-width: auto;
			min-height: auto;
			width: 290px;
			flex-basis: 290px;
			// position: absolute;
			// top: 0;
			// left: 0;
			// bottom: 0;
		}
	}
	.card--thinner & {
		@include respond-to($vpC) {
			width: 200px;
		}
	}
	.card--person & {
		display: inline-block;
		width: 180px;
		height: 180px;
		min-height: 180px;
		min-width: 180px;
		float: left;
		border-radius: $border-radius;
		border-bottom: 0;
		aspect-ratio: 1 / 1;
		img {
			width: 100%;
			aspect-ratio: 1 / 1;
		}
		// top: 0;
		// bottom: 0;
		// left: 0;
		@include respond-to-min-max($vpD, $vpE) {
			width: 120px;
			height: 120px;
			min-height: 120px;
			min-width: 120px;
		}
	}
}

a.card__media {
	border-bottom: 0;
// 	transition: all, ease-in-out, 0.3s;
// 	@include link-active-styles {
// 		filter: grayscale(.5);
// 	}
// 	span {
// 		@include a11yhide;
// 	}
}

.card__content {
	padding: $internal-spacing $internal-spacing*1.5;
	z-index: 5;
	position: relative;
	.card--horizontal &,
	.card--route &,
	.card--person & {
		width: 100%;
		flex: 1;
		padding: $internal-spacing*.5 $internal-spacing;
		@include respond-to($vpC) {
			width: 70%;
		}
		@include respond-to($vpE) {
			padding: $internal-spacing $internal-spacing*1.5;
		}
	}
	.card--icon & {
		padding: $internal-spacing;
	}
	p:last-child{
		margin-bottom: 0;
	}
	.card--centred &{
		text-align: center;
	}
}

.card__heading {
	@extend .heading;
	font-size: rem($typeXL);
	line-height: 1.5;
	transition: all, ease-in-out, 0.3s;
	padding-bottom: $internal-spacing*.5;
	.card--news &,
	.card--warden-news & {
		color: $white;
	}
	.card--event & {
		color: $white;
		border-bottom: 1px solid $woodlands_20;
		padding-bottom: $internal-spacing;
		// margin-bottom: $internal-spacing;
	}
	.card--links &,
	.card--icon & {
		font-size: rem($typeXL);
	}
	.card--person & {
		line-height: 1.3;
		font-size: rem($typeL);
		padding-bottom: $internal-spacing*.25;
		@include respond-to-min-max($vpC, $vpD) {
			font-size: rem($typeXL);
		}
		@include respond-to($vpE) {
			font-size: rem($typeXL);
		}
	}
	.nav-grid & {
		line-height: 1.2;
	}
}

.card__subheading{
	font-size: rem($typeL);
	line-height: 1.3;
	transition: all, ease-in-out, 0.3s;
	margin-top: $internal-spacing*.5;
	margin-bottom: $internal-spacing*.5;
	.card--route & {
		line-height: 30px;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.card__excerpt {
	font-size: rem($typeM);
	margin-bottom: $internal-spacing*.5;
	line-height: 1.5;
	&:last-child{
		margin-bottom: 0;
	}
	&--small {
		font-size: rem($typeS);
		line-height: 1.4;
		margin-bottom: $internal-spacing*.25;
	}
	.card--person & {
		margin-bottom: $internal-spacing*.25;
		@include respond-to-min-max($vpD, $vpE) {
			font-size: rem($typeMe);
		}
	}
}

.card__list {
	margin-top: $internal-spacing*.5;
}

.card__meta {
	position: relative;
	font-size: rem($typeMe);
	list-style: none;
	margin-bottom: $internal-spacing*.5;
	.card--person & {
		border-top: $limestone_40 1px solid;
		padding-top: $internal-spacing*.15;
		margin-top: $internal-spacing*.5;
	}
	.card__meta__heading {
		font-weight: $fontSemiBold;
	}
	.card--route & {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-row-gap: 5px;
		grid-column-gap: 10px;
		border-top: $limestone_40 1px solid;
		padding-top: $internal-spacing*.5;
	}
	.card--event &,
	.card--planning &,
	.card--news &,
	.card--warden-news & {
		display: inline-grid;
		grid-template-columns: auto auto;
		grid-row-gap: 5px;
		grid-column-gap: 10px;
	}
	dt {
		font-family: $fontHeading;
		.card--route & {
			color: $limestone_20;
		}
	}
	dd {
		margin-inline-start: 0;
	}
	li {
		margin-bottom: $internal-spacing*.25;
		svg {
			width: 20px;
			height: 20px;
			vertical-align: text-top;
			margin: 0 10px 0 0;
			display: inline-block;
			stroke: $heathlands_40;
			stroke-width: 1.5px;
			&:nth-child(2n){
				margin-left: $internal-spacing;
			}
		}
		a {
			overflow-wrap: break-word;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.card__list {
	@extend .unordered_list;
	font-size: rem($typeS);
	position: relative;
	margin-bottom: $internal-spacing*.5;
}