// Routes
// ------------------------------------------------------------

.route-meta {
	width: 100%;
	display: grid;
	@include gridCalcRepeat(2, 0, 20px);
	@include respond-to($vpB) {
		@include gridCalcRepeat(3, 0, 20px);
	}
	@include respond-to($vpC) {
		display: flex;
		align-items: stretch;
		flex-wrap: nowrap;
		gap: $internal-spacing;
	}
	&__item {
		display: inline-block;
		@include respond-to($vpC) {
			flex: 1 1 auto;
		}
	}
	&__label {
		font-size: rem($typeS);
		font-family: $fontHeading;
		color: $limestone_20;
	}
	&__value {
		font-size: rem($typeM);
		color: $black;
	}
}

.page-meta {
	display: inline-grid;
  	gap: $internal-spacing;
  	grid-template-columns: auto auto;
	&__label {
		font-size: rem($typeM);
		font-family: $fontHeading;
		color: $limestone_20;
	}
	&__value {
		font-size: rem($typeM);
		color: $black;
	}
}