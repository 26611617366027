
@use "sass:math";

.tabs {
    position: relative;
}

.tabs__tab {
    float: left;
    width: 100%;
    margin-right: -100%;
}

.tab-faux-heading {
	font-size: rem($typeL);
	font-family: $fontHeading;
	position: relative;
	padding: 5px 0;
	@include respond-to-max($vpBB) {
		margin-top: $internal-spacing*2;
	}
}

.tabs__label {
    font-size: rem($typeL);
    position: absolute;
    left: 130px;
    @include respond-to($vpBB) {
    	left: auto;
    	right: 0;
    }
    z-index: 12;
    display: inline-block;
    cursor: pointer;
    transition: background ease 0.2s;
    padding: 5px;
    width: auto;
	svg {
		width: 22px;
		height: 22px;
		position: relative;
		top: -2px;
		margin-right: 5px;
		color: $limestone_30;
	}
	.tabs__tab--list & {
		left: 0;
		@include respond-to($vpBB) {
			left: auto;
			right: 130px;
		}
	}
	@include link-active-styles {
		color: $grasslands_20;
		svg {
			color: $grasslands_20;
		}
	}
}

.tabs__content {
    position: relative;
    width: 100%;
    left: 0;
    z-index: 0;
    visibility: hidden;
    background: #fff;
}

.tabs__input {
	position: relative;
	z-index: 0;
	top: auto;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0px;
	border: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
	position: absolute;
}

.tabs__input:checked + .tabs__label {
    svg {
		color: $grasslands_20;
	}
}

.tabs__input:checked + .tabs__label + .tabs__content {
    visibility: visible;
    position: relative;
    z-index: 10;
}