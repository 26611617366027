@use 'sass:math';

.quote,
blockquote,
.typebase blockquote {
	border-top: 1px solid $limestone_40;
	border-bottom: 1px solid $limestone_40;
	padding: $internal-spacing*2 0 $internal-spacing*2 $internal-spacing*5;
	position: relative;
	margin: $internal-spacing*2 0;
	.typebase & {
		margin-top: 3.2rem;
	}
	&:before {
		content: "";
		width: 60px;
		height: 55px;
		position: absolute;
		left: 0;
		top: 48px;
		background-image: url('/assets/img/speech-mark.svg');
		background-size: cover;
		background-repeat: no-repeat;
	}
	p,
	.quote__content {
		font-size: rem($typeXL);
		margin-bottom: math.div($internal-spacing,2);
		line-height: 1.6;
	}
	cite,
	.quote__citation {
		margin-bottom: math.div($internal-spacing,2);
		font-size: rem($typeDefault);
		font-style: normal;
	}
}