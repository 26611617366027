// Location
// ------------------------------------------------------------

.location {
	margin-top: $internal-spacing*2;
}

.location__heading {
	@extend .heading;
	font-size: rem($typeM);
	line-height: 1.3;
	transition: all, ease-in-out, 0.3s;
	margin-bottom: $internal-spacing*.75;
	svg {
		width: 20px;
		height: 20px;
		vertical-align: text-top;
		margin: 8px 10px 0 0;
		display: inline-block;
		filter: brightness(0) saturate(100%) invert(47%) sepia(82%) saturate(1971%) hue-rotate(336deg) brightness(111%) contrast(84%);
	}
}

.location__content {
	p {
		@extend .text;
		font-size: rem($typeS);
		margin-top: rem($typeDefault);
		display: block;
	}
}

.location__map-link{
	border-bottom: 0;
	svg {
		height: 18px;
		width: 18px;
		filter: brightness(0) saturate(100%) invert(12%) sepia(88%) saturate(4721%) hue-rotate(273deg) brightness(104%) contrast(88%);
		margin-right: $internal-spacing*.25;
		vertical-align: text-top;
	}
}