@use 'sass:math';

.bubble,
a.bubble {
	color: $black;
	border: 1px solid $ocean_rivers_30;
	padding: 0 $internal-spacing*.5;
	border-radius: $internal-spacing;
	font-size: rem($typeXS);
	display: inline-block;
	vertical-align: top;
	line-height: 24px;
	.card & {
		margin-top: $internal-spacing*.5;
	}
	& + .bubble {
		// margin-left: $internal-spacing;
	}
	&--green {
		border-color: $grasslands_30;
		color: $white;
	}
}

// a.bubble {
// 	@include link-active-styles {
// 		border-color: $heathlands_40;
// 		background-color: $heathlands_40;
// 	}
// 	&--theme {
// 		@include link-active-styles {
// 			border-color: $rocky_10;
// 			background-color: $rocky_40;
// 		}
// 	}
// 	&--workstream {
// 		@include link-active-styles {
// 			border-color: $heathlands_30;
// 			background-color: $heathlands_30;
// 		}
// 	}
// }