// Sidebar
.grid-sidebar {
	&__main {
		margin-bottom: $internal-spacing*2;
		.section--split & {
			padding: $internal-spacing*2 0;
		}
	}
	@include respond-to($vpC) {
		@include gridCalc('true');
		grid-column-gap: 10px !important;
		&--1-3 {
			grid-column-gap: 40px !important;
		}
		&__main {
			margin-bottom: 0;
			@include gridPosition(1, 7);
			@include gridRow(1);
			width: 65%;
			float: left;
			@supports (display: grid) {
				width: auto;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 65%;
				float: left;
			}
			.grid-sidebar--reverse & {
				@include gridPosition(6, 8);
				float: right;
				@supports (display: -ms-grid) {
					float: right;
				}
			}
			.grid-sidebar--1-3 & {
				@include gridPosition(1, 8);
			}
			.grid-sidebar--reverse.grid-sidebar--1-3 & {
				@include gridPosition(5, 8);
			}
		}
		&__aside {
			@include gridPosition(9, 5);
			@include gridRow(1);
			width: 30%;
			float: right;
			margin-left: 5%;
			@supports (display: grid) {
				width: auto;
				float: none;
				margin-left: 0;
			}
			@supports (display: -ms-grid) {
				width: 30%;
				float: right;
				margin-left: 5%;
			}
			.grid-sidebar--reverse & {
				@include gridPosition(1, 5);
				float: left;
				margin-left: 0;
				margin-right: 5%;
				@supports (display: -ms-grid) {
					float: left;
					margin-right: 5%;
				}
			}
			.grid-sidebar--1-3 & {
				@include gridPosition(9, 4);
			}
			.grid-sidebar--reverse.grid-sidebar--1-3 & {
				@include gridPosition(1, 4);
			}
		}
	}
	@include respond-to($vpD) {
		&__main {
			@include gridPosition(1, 7);
			.grid-sidebar--reverse & {
				@include gridPosition(6, 8);
			}
			.grid-sidebar--1-3 & {
				@include gridPosition(1, 9);
			}
			.grid-sidebar--reverse.grid-sidebar--1-3 & {
				@include gridPosition(4, 9);
			}
		}
		&__aside {
			@include gridPosition(9, 5);
			.grid-sidebar--reverse & {
				@include gridPosition(1, 5);
			}
			.grid-sidebar--1-3 & {
				@include gridPosition(10, 3);
			}
			.grid-sidebar--reverse.grid-sidebar--1-3 & {
				@include gridPosition(1, 3);
			}
		}
	}
}

.grid-thirds {
	&__main {
		margin-top: $internal-spacing*2;
	}
	@include respond-to($vpC) {
		@include gridCalc('false');
		@supports (display: grid) {
			grid-column-gap: $internal-spacing*2;
		}
		&__main {
			margin-top: 0;
			@include gridPosition(5, 8);
			width: 65%;
			float: left;
			@supports (display: grid) {
				width: auto;
				float: none;
			}
		}
		&__aside {
			@include gridPosition(1, 4);
			width: 31%;
			float: left;
			margin-right: 4%;
			@supports (display: grid) {
				width: auto;
				float: none;
				margin-right: 0;
			}
		}
		&--reverse {
			.grid-thirds__main {
				@include gridPosition(1, 8);
				order: 1;
			}
			.grid-thirds__aside {
				@include gridPosition(9, 4);
				order: 2;
			}
		}
	}
}

// 1 col grid
.grid-1up {
	@include gridCalcRepeat(1, $internal-spacing, $internal-spacing);
}

// 2 column grid
.grid-2up {
	@include gridCalcRepeat(1, $internal-spacing, $internal-spacing);
	@include respond-to($vpC) {
		@include gridCalcRepeat(2, $internal-spacing*2, $internal-spacing*2);
	}
	> div, > a {
		@include respond-to($vpC) {
			width: 49%;
			margin-left: 2%;
			float: left;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 49%;
				float: left;
			}
		}
	}
	&.grid-2up--staggered  {
		@include respond-to($vpC) {
			padding-bottom: 90px;
			.card{
				&:nth-child(2n+2) {
					position: relative;
					top: 90px;
				}
			}
		}
	}
	.section--cycling & {
		@include respond-to($vpBB){
			width: 80%;
			margin: 0 auto;
		}
	}
}

// 2 column grid
.grid-people {
	@include gridCalcRepeat(1, $internal-spacing, $internal-spacing);
	@include respond-to-min-max($vpBB, $vpC) {
		@include gridCalcRepeat(2, $internal-spacing, $internal-spacing);
	}
	@include respond-to($vpD) {
		@include gridCalcRepeat(2, $internal-spacing*2, $internal-spacing*2);
		grid-column-gap: 40px !important;
	}
	> div, > a {
		@include respond-to($vpC) {
			width: 49%;
			margin-left: 2%;
			float: left;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 49%;
				float: left;
			}
		}
	}
}

// 3 column grid
.grid-3up {
	@include gridCalcRepeat(1, $internal-spacing, $internal-spacing);
	@include respond-to-min-max($vpB,$vpC) {
		@include gridCalcRepeat(2);
		grid-column-gap: 20px !important;
		grid-row-gap: 20px !important;
	}
	@include respond-to($vpC) {
		@include gridCalcRepeat(3,40px,40px);
	}
	> div, > a {
		@include respond-to-max($vpC) {
			// margin-bottom: $internal-spacing;
		}
		@include respond-to($vpC) {
			width: 32%;
			margin-left: 2%;
			float: left;
			&:nth-child(3n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 33%;
				float: left;
			}
		}
		@include respond-to-min-max($vpB, $vpC) {
			width: 48%;
			margin-left: 2%;
			float: left;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 50%;
				float: left;
			}
		}
	}
	&.grid-3up--staggered  {
		@include respond-to($vpC) {
			padding-bottom: 90px;
			.card{
				&:nth-child(3n+2) {
					position: relative;
					top: 90px;
				}
			}
		}
	}
	.section--exploring & {
		width: 80%;
		margin: 0 auto;
	}
}

// 3 column grid in a listing
.grid-3up-listing {
	@include gridCalcRepeat(1, $internal-spacing, $internal-spacing);
	@include respond-to-min-max($vpB,$vpE) {
		@include gridCalcRepeat(2);
		grid-column-gap: 20px !important;
		grid-row-gap: 20px !important;
	}
	@include respond-to($vpE) {
		@include gridCalcRepeat(3,40px,40px);
	}
	> div, > a {
		@include respond-to($vpE) {
			width: 32%;
			margin-left: 2%;
			float: left;
			&:nth-child(3n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 33%;
				float: left;
			}
		}
		@include respond-to-min-max($vpB, $vpE) {
			width: 48%;
			margin-left: 2%;
			float: left;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 50%;
				float: left;
			}
		}
	}
}

// 4 column grid
.grid-4up {
	@include gridCalcRepeat(1, $internal-spacing, $internal-spacing);
	width: 100%;
	clear: both;
	@include respond-to($vpB) {
		@include gridCalcRepeat(2, $internal-spacing, $internal-spacing);
	}
	@include respond-to($vpC) {
		@include gridCalcRepeat(3, $internal-spacing*2, $internal-spacing*2);
	}
	@include respond-to($vpDD) {
		@include gridCalcRepeat(4, $internal-spacing*2, $internal-spacing*2);
	}
	> div, > a {
		@include respond-to-max($vpC) {
			width: 49%;
			margin-left: 2%;
			float: left;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				margin-bottom: 0;
				float: none;
				clear: none;
			}
			@supports (display: -ms-grid) {
				width: 49%;
				float: left;
			}
		}
		@include respond-to($vpC) {
			width: 32%;
			margin-left: 2%;
			float: left;
			&:nth-child(3n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 33%;
				float: left;
			}
		}
		@include respond-to($vpD) {
			width: 23.5%;
			margin-left: 2%;
			float: left;
			&:nth-child(4n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 23.5%;
				float: left;
			}
		}
	}
	&.grid-4up--staggered  {
		@include respond-to-min-max($vpC, $vpDD) {
			padding-bottom: 90px;
			.card{
				margin-bottom: 0;
				&:nth-child(3n+2) {
					position: relative;
					top: 90px;
				}
			}
		}
		@include respond-to($vpDD) {
			padding-bottom: 90px;
			.card{
				&:nth-child(2n) {
					position: relative;
					top: 90px;
				}
			}
		}
	}
}

// 5 column grid
.grid-5up {
	@include gridCalcRepeat(2);
	@include respond-to($vpB) {
		@include gridCalcRepeat(3);
	}
	@include respond-to($vpC) {
		display: flex;
		grid-column-gap: 40px !important;
		align-items: center;
  		justify-content: center;
  		padding-top: $internal-spacing*2;
	}
	> div, > a {
		margin-bottom: $internal-spacing;
		text-align: center;
		@include respond-to($vpC) {
			width: 19%;
			margin-left: 1%;
			float: left;
			&:nth-child(5n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: flex) {
				width: auto;
				margin-left: 0;
				float: none;
				&:nth-child(3n) {
					transform: scale(1.2);
				}
			}
			@supports (display: -ms-grid) {
				width: 19%;
				float: left;
			}
		}
	}
}

// 6 column grid
// .grid-6up {
// 	@include gridCalcRepeat(2);
// 	@include respond-to($vpC) {
// 		display: flex;
// 		grid-column-gap: 40px !important;
// 		align-items: center;
//   		justify-content: center;
//   		padding-top: $internal-spacing*2;
// 	}
// 	> div, > a {
// 		margin-bottom: $internal-spacing;
// 		@include respond-to($vpC) {
// 			width: 15%;
// 			margin-left: 2%;
// 			float: left;
// 			&:nth-child(5n+1) {
// 				margin-left: 0;
// 				clear: left;
// 			}
// 			@supports (display: flex) {
// 				width: auto;
// 				margin-left: 0;
// 				float: none;
// 			}
// 			@supports (display: -ms-grid) {
// 				width: 15%;
// 				float: left;
// 			}
// 		}
// 	}
// }


// Grid col
.grid__col {
	position: relative;
	@include pie-clearfix;
	img,
	iframe {
		max-width: 100%;
	}
}

// Grid homepage collage

.grid-collage {
	display: grid;
	grid-template-columns: repeat(8, 1fr);
  	grid-gap: $internal-spacing;
  	grid-template-areas:
		"a a a a d d d d"
		"e e e e e e e e"
		"f f g g g g g g"
		". . g g g g g g";
	@include respond-to($vpB) {
		grid-template-areas:
			"a a a a c c c c"
			"b b b b d d d d"
			"b b b b e e e e"
			"b b b b e e e e"
			"f f f g g g g g"
			". . . g g g g g";
		}
	@include respond-to($vpC) {
		grid-gap: $internal-spacing*2;
		grid-template-columns: repeat(12, 1fr);
		grid-auto-rows: minmax(120px, auto);
		grid-template-areas:
			". . a a a a . . . . . ."
			"b b b b b b c c d d d ."
			"b b b b b b e e e e e ."
			"b b b b b b e e e e e ."
			". . f f g g g h h h h ."
			". . . . g g g h h h h ."
			". . . . . . . h h h h .";
	}
	div {
		border-radius: $border-radius;
		overflow: hidden;
	}
	.patch {
		position: relative;
		&__inner {
			border-radius: $border-radius;
		}
	}
	.patch-1 {
		display: none;
		@include respond-to($vpB) {
			grid-area: c;
			display: inline-block;
		}
		.patch__inner {
			width: 120px;
			height: 120px;
			display: flex;
      		justify-content: center;
      		background-color: $ocean_rivers_40;
      		background-image: url('/assets/img/patches/stones.svg');
      		background-size: 80%;
      		background-position: center;
      		background-repeat: no-repeat;
		}
	}
	.patch-2 {
		grid-area: f;
		.patch__inner {
			width: 120px;
			height: 120px;
			display: flex;
      		justify-content: center;
      		background-color: $moors_30;
      		background-image: url('/assets/img/patches/corn.svg');
      		background-size: 90%;
      		background-position: bottom right;
      		background-repeat: no-repeat;
      		float: right;
		}
	}
	.media {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	.media-1 {
		display: none;
		@include respond-to($vpB) {
			grid-area: b;
			display: inline-block;
		}
	}
	.media-2 {
		grid-area: h;
		display: none;
		@include respond-to($vpC) {
			display: inline-block;
		}
	}
	.stat {
		padding: $internal-spacing*.5 $internal-spacing $internal-spacing $internal-spacing;
		@include respond-to($vpB) {
			padding: $internal-spacing*1.5;
		}
		font-size: rem($typeL);
		color: $black;
		font-family: $fontHeading;
		line-height: 1.4;
		span {
			font-size: rem($typeXXXL);
			&.wide {
				display: block;
			}
		}
	}
	.stat-1 {
		grid-area: a;
		background-color: $woodlands_40;
	}
	.stat-2 {
		grid-area: d;
		background-color: $grasslands_40;
	}
	.stat-3 {
		grid-area: g;
		background-color: $sands_40;
	}
	.collage-content {
		grid-area: e;
		.typebase p {
			font-size: rem($typeL);
		}
	}
}

.grid-maps {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
  	grid-gap: $internal-spacing;
  	grid-template-areas:
		"c c c c";
	@include respond-to($vpC) {
		grid-gap: $internal-spacing*2;
		grid-auto-rows: minmax(120px, auto);
		grid-template-areas:
			"a a a ."
			"a a a ."
			"a a a b"
			"c c c c"
			"c c c c";
	}
	div {
		border-radius: $border-radius;
	}
	.patch {
		display: none;
		@include respond-to($vpC) {
			display: inline-block;
		}
		grid-area: b;
		position: relative;
		.patch__inner {
			border-radius: $border-radius;
			width: 120px;
			height: 120px;
      		background-color: $grasslands_10;
      		background-image: url('/assets/img/patches/signpost.svg');
      		background-position: center bottom;
      		background-repeat: no-repeat;
		}
	}
	.media {
		display: none;
		@include respond-to($vpC) {
			display: inline-block;
		}
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		grid-area: a;
	}
	.content {
		grid-area: c;
		.content__inner {
			background-color: $grasslands_30;
			color: $black;
			padding: $internal-spacing $internal-spacing*1.5;
			.content__heading {
				font-size: rem($typeXL);
				font-family: $fontHeading;
			}
			.typebase p {
				font-size: rem($typeM);
			}
		}
	}
	.section--split & {
		@include respond-to($vpC) {
			width: 80%;
			margin: 0 auto;
		}
	}
}