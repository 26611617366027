.share {
	overflow: hidden;
	background-color: $limestone_60;
	margin-bottom: $internal-spacing*2;
	padding: $internal-spacing;
	border-radius: $border-radius;
}

.share__heading {
	font-family: $fontHeading;
	font-size: rem($typeL);
	display: block;
	margin-bottom: 10px;
}

.share__list {
	display: inline-block;
	list-style: none;
	display: flex;
	gap: 8px;
	li {
		// display: inline-block;
		// margin-left: 15px;
		a, span {
			width: 38px;
			height: 38px;
			display: block;
			background-color: $grasslands_30;
			border-bottom: 0;
			border-radius: 50%;
			vertical-align: middle;
			text-align: center;
			span {
				@include a11yhide;
			}
			svg {
				width: 24px;
	            height: 24px;
	            position: relative;
	            top: 7px;
	            color: $grasslands_10;
			}
			@include link-active-styles {
				cursor: pointer;
			}
		}
	}
}