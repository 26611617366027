@use 'sass:math';

.listing {
	@include pie-clearfix;
    position: relative;
    display: block;
    clear: both;
    .card {
    	margin-bottom: $internal-spacing*2;
    	&:last-child {
    		margin-bottom: 0;
    	}
    }
}

.listing-routes {
	@include pie-clearfix;
    position: relative;
    display: block;
    clear: both;
    .card {
    	margin-bottom: $internal-spacing;
    	&:last-child {
    		margin-bottom: 0;
    	}
    }
}

.listing-item {
    @include pie-clearfix;
    position: relative;
    margin-bottom: $internal-spacing*2;
    padding-bottom: $internal-spacing*2;
    border-bottom: 1px solid $limestone_40;
    &:last-of-type {
    	border-bottom: 0;
    }
}

.listing-item__heading {
    @extend .heading;
    font-size: rem($typeL);
    line-height: 1.4;
    margin-bottom: math.div($internal-spacing,2);
}

.listing-item__subheading {
    font-size: rem($typeDefault);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: math.div($internal-spacing,2);
}

.listing-item__meta {
    list-style: none;
    font-size: rem($typeDefault);
    margin: math.div($internal-spacing,1.5) 0;
    li {
        display: inline-block;
        margin-right: math.div($internal-spacing,2);
    }
}

.listing-item__content {
	font-size: rem($typeDefault);
}