// ------------------------------------------------------------
// Alerts
// ------------------------------------------------------------

.mc4wp-response {
	margin-top: $internal-spacing;
}

.alert,
.mc4wp-alert {
	padding: $internal-spacing*.5 $internal-spacing;
	position: relative;
	background-color: $heathlands_20;
	border: 2px solid $heathlands_30;
	color: $cHeading;
	border-radius: $border-radius;
	p {
		font-size: rem($typeM);
		margin-top: 0;
	}
	.card & {
		margin: 10px 0;
	}
}

.alert--notice,
.mc4wp-notice {
	border-color: $sands_20;
	background-color: $sands_40;
}

.alert--error,
.mc4wp-error {
	border-color: $rocky_20;
	background-color: $rocky_40;
}

.alert--success,
.mc4wp-success {
	border-color: $grasslands_20;
	background-color: $grasslands_40;
}