html {
	font-size: $scaleRoot + px;
	scroll-behavior: smooth;
	@media (max-width: $vpDeviceSafe) {
		-webkit-text-size-adjust: 100%;
	}
}

body {
	color: $cText;
	font-family: $fontDefault;
	line-height: $lineheightBase;
	overflow-x: hidden;
	font-weight: $fontNormal;
	position: relative;
	// background-color: $skies_20;
}

a,
.fauxlink {
	text-decoration: none;
	cursor: pointer;
	color: $cText;
	border-bottom: 2px solid $cAction;
	@include link-active-styles {
		border-bottom: 2px solid $cActionRespond;
	}
}

.skiplink 	{
	background: #fff;
	color: $cHeading;
	border: 0;
	display: block;
	font-size: 16px;
	line-height: 40px;
	padding: 0 20px;
	position: absolute;
	top: -60px;
	left: 0;
	transition: all ease-in-out 0.1s;
	&:focus,
	&:active {
		top: 0;
		z-index: 80;
	}
}

// Overide iOS telephone styling on links
a[href^="tel"] {
	color: inherit;
	text-decoration: none;
}

// Max with utl class
.max-width {
	max-width: 100%;
	height: auto;
	display: block;
	img {
		max-width: 100%;
		height: auto;
		display: block;
	}
}
// Max with for copy
.max-meter {
	width: 100%;
	max-width: 800px;
	display: inline-block;
	&--centered {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

// Full width utl class
.full-width {
	width: 100%;
	height: auto;
	display: block;
}

// Further sensible resets
strong, b {
	font-weight: $fontSemiBold;
	@include sharp-text;
}

input, textarea, select, button {
	font-family: $fontDefault;
}

pre {
	white-space: pre-wrap;
	overflow-x: auto;
	tab-size: 4;
	font-size: rem($typeDefault);
}

// Hide recaptcha

.grecaptcha-badge {
    visibility: hidden;
}