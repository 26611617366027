@use 'sass:math';

.with-leader {
	margin-top: $internal-spacing;
}

.with-leader--long {
	margin-top: $internal-spacing*2;
}

.with-trailer {
	margin-bottom: $internal-spacing;
}

.is-sticky {
	position: sticky;
    top: $internal-spacing*8;
}

.hide-mobile {
	@include respond-to-max($vpC) {
		display: none;
	}
}