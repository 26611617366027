@use 'sass:math';

.header {
	display: block;
	position: relative;
	background-color: $white;
	margin: 0;
	z-index: 11;
	border: 1px solid $limestone_40;
	padding: 8px;
	border-radius: $border-radius;
	box-shadow: 0px 0px 15px 0px rgba(0,0,0,.25);
	margin: 0 8px;
	@include respond-to($vpMenu) {
		box-shadow: 0px 0px 30px 0px rgba(0,0,0,.25);
		position: fixed;
		padding: 14px;
		top: $internal-spacing;
		left: $internal-spacing;
		right: $internal-spacing;
		max-width: $gridMax;
		margin: 0;
	}
	@include respond-to($gridMax) {
		width: calc($gridMax);
		left: auto;
		right: auto;
	}
	.button {
		display: none;
		@include respond-to($vpMenu) {
			display: inline-block;
			float: right;
			margin-left: $internal-spacing;
		}
	}
	.header--wardens & {
		background-color: $limestone_60;
		box-shadow: none;
	}
}

.header-wrap {
	position: relative;
	background-color: $woodlands_10;
	padding-top: 8px;
	@include respond-to($vpMenu) {
		padding-top: $internal-spacing*6;
	}
	&--exploring {
		background-color: $ocean_rivers_40;
	}
	&--wardenhome {
		background-color: $white;
	}
	&--route {
		background-color: $woodlands_20;
	}
	&--home {
		background-color: $grasslands_10;
	}
	&--section {
		background-color: $woodlands_20;
	}
	&--cycling {
		background-color: $moors_40;
	}
	&--horse-riding {
		background-color: $sands_40;
	}
	&--guided-walks {
		background-color: $grasslands_40;
	}
}

.header__logo {
	width: 118px;
	height: 44px;
	background-image: url('../img/logo-combined.svg');
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	text-indent: -9999em;
	border-bottom: none;
	color: $white;
	z-index: 2;
	position: relative;
	@include respond-to($vpMenu) {
		width: 204px;
		height: 76px;
	}
	@include link-active-styles {
		border-bottom: none;
	}
}