@use 'sass:math';

.footer__wrap {
	background-image: url('/assets/img/background/wildflower-bee-900.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 200px 8px $internal-spacing 8px;
	@include respond-to($vpMenu) {
		padding: 600px 14px $internal-spacing*3 14px;
		background-image: url('/assets/img/background/wildflower-bee-1400.jpg');
	}
	&--wardens {
		background-image: url('/assets/img/background/footer-wardens-900.jpg');
		@include respond-to($vpMenu) {
			background-image: url('/assets/img/background/footer-wardens-1400.jpg');
		}
	}
}

.footer {
	@include pie-clearfix;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	min-width: auto;
	max-width: $gridMax;
	background-color: $woodlands_10;
    border-radius: $border-radius;
    color: $white;
    position: relative;
    z-index: 1;
    @include respond-to($vpMenu) {
		box-shadow: 0px 0px 30px 0px rgba(0,0,0,.25);
		position: relative;
		max-width: $gridMax;
		margin: 0 auto;
	}
	@include respond-to($gridMax) {
		width: calc($gridMax - 40px);
	}
}

.footer__newsletter {
	background-color: $grasslands_10;
	padding: $internal-spacing;
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;
	@include respond-to($vpC) {
		padding: $internal-spacing*2 $internal-spacing*5;
	}
}

.footer-grid {
	display: grid;
	padding: $internal-spacing;
	gap: $internal-spacing;
	grid-template-columns: repeat(6, minmax(0, 1fr));
	grid-template-areas:
	"a a a b b b"
	"c c c c d d"
	"e e e e f f";
	@include respond-to($vpB) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-template-areas:
		"a a b b b b"
		"c c c c d d"
		"e e e e e f";
	}
	@include respond-to($vpC) {
		padding: $internal-spacing*2 $internal-spacing*5;
		gap: $internal-spacing*2;
		grid-template-columns: repeat(8, minmax(0, 1fr));
		grid-template-areas:
		"a a b b c c d d"
		"a a b b e e e f";
	}
}

// Patches

.footer__patches {
	grid-area: a;
}
.patch {
	display: inline-block;
	&--landscape {
		padding: 0 20px 20px 0;
		max-width: 100%;
		margin: 0 auto;
		clear: both;
		display: block;
	}
	&--river {
		padding-top: 20px;
		float: right;
	}
	&--stones {
		padding: 0;
		float: left;
		clear: left;
	}
}

.footer__links {
	grid-area: b;
}

.footer__contact {
	grid-area: c;
}

.footer__social {
	grid-area: d;
	width: 100%;
}

.footer__copyright {
	grid-area: e;
}

.footer__logo {
	grid-area: f;
	img {
		float: right;
		max-width: 100%;
	}
}

.footer__heading {
	font-family: $fontHeading;
    font-size: rem($typeL);
    color: $white;
    margin-bottom: 10px;
    display: block;
}

.footer__copy {
    font-size: rem($typeMe);
    margin-bottom: $internal-spacing;
}

.footer__list {
    list-style: none;
    font-size: rem($typeMe);
}

.footer__social__icons {
	list-style: none;
	display: flex;
	align-items: flex-start;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: flex-end;
	gap: 8px;
}
.footer__social__icons__icon {
	a {
		display: block;
		background-color: $grasslands_20;
		border-radius: 20px;
		width: 40px;
		height: 40px;
		border-bottom: 0;
		svg {
	        width: 26px;
	        height: 26px;
	        vertical-align: middle;
	        position: relative;
	        top: 7px;
	        left: 7px;
	    }
	    span {
	    	@include a11yhide;
	    }
	    @include link-active-styles {
	    	background-color: $grasslands_30;
	    }
	}
}

.footer__list__item {
    position: relative;
    margin-bottom: $internal-spacing*.5;
    span {
    	font-family: $fontHeading;
    	padding-right: 5px;
    }
    a {
        color: $grasslands_30;
        display: inline-block;
        position: relative;
        border-bottom: 2px solid transparent;
        @include link-active-styles {
            border-color: $grasslands_30;
        }
    }
    &--active a {
    	border-bottom: $grasslands_30;
        text-decoration: none;
    }
}

.footer__meta {
    font-size: rem($typeXS);
    color: #fff;
    p {
    	margin-bottom: 16px;
    }
    a {
        color: $grasslands_30;
        border-bottom: 2px solid transparent;
        @include link-active-styles {
            border-color: $grasslands_30;
        }
    }
}