
@use "sass:math";

.tables {
	margin: 4em 0;
	position: relative;
}

// ---------------------------------------
// Table styles
// ---------------------------------------

.table-styled {
	width: 100%;
	font-size: rem($typeMe);
	td {
		position: relative;
		line-height: 1.4;
		vertical-align: top;
	}
	@include respond-to-max($vpTable) {
		border-collapse: separate;
		table, thead, tbody, th, td, tr {
			display: block;
		}
		thead, th {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		tr {
			margin-bottom: $internal-spacing;
			overflow: hidden;
			&:first-child {
				border-top: none;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		td {
			position: relative;
			background: #fff;
			border-bottom: $default-border-width solid $limestone_40;
			border-right: $default-border-width solid $limestone_40;
			border-left: $default-border-width solid $limestone_40;
			vertical-align: top;
			padding: $internal-spacing*0.25 0 $internal-spacing*0.25 $internal-spacing*0.25;
			padding-left: 130px;
            font-size: rem($typeS);
            &:first-of-type {
                border-top: $default-border-width solid $limestone_40;
            }
			&:before {
				display: block;
				width: 120px;
                height: 100%;
                background-color: $limestone_40;
				word-break: break-word;
				content: attr(title);
				padding: $internal-spacing*0.25;
				color: $cHeading;
				font-weight: 600;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
	@include respond-to($vpTable) {
		border: $default-border-width solid $limestone_20;
		th, td {
			border-right: $default-border-width solid $limestone_20;
			&:last-child {
				border-right: none;
			}
		}
		thead {
			th {
				padding: $internal-spacing*0.5;
				background-color: $limestone_30;
                border-color: $limestone_20;
				color: $cHeading;
				.table-sortable & {
					@include link-active-styles {
						cursor: pointer;
					}
				}
			}
		}
		tbody {
			tr {
				background: #fff;
				border-bottom: $default-border-width solid $limestone_20;
				&:nth-child(even) {
                    background-color: $limestone_40;
				}
			}
			td {
				padding: math.div($internal-spacing, 3) $internal-spacing*0.5;
			}
		}
	}
}

.table-sortable {
	th {
		&:hover {
			.js & {
				cursor: pointer;
			}
		}
		svg {
			display: none;
			width: 16px;
			height: 16px;
			.js & {
				display: inline-block;
			}
		}
	}
}

.table-filter {
	display: none;
	.js & {
		display: inline-block;
	}
}
