// Maps
// ------------------------------------------------------------

// Map container

 .map-container {
	height: 100%;
	width: 100%;
	display: block;
	min-height: 800px;
	&--route {
		min-height: auto;
		@include ratio(3 4);
		@include respond-to ($vpB) {
			@include ratio(1 1);
		}
		@include respond-to ($vpD) {
			@include ratio(4 3);
		}
	}
}

#map-tab, #list-tab {
	scroll-padding-top: 100px;
}

// Towns map
.gm-style-iw-chr {
	position: absolute;
	top: -5px;
	right: -5px;
}

.custom-infowindow {
    padding: 15px 5px 5px 5px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    min-width: 250px;
    &--route {
    	padding-top: 5px;
    }
}

.custom-infowindow h3 {
    margin: 10px 0 0 0;
    font-size: rem($typeM);
    font-weight: 600;
    color: $woodlands_10;
}

.custom-infowindow p {
    font-size: rem($typeS);
    line-height: 1.4;
    color: $black;
    margin-top: 6px;
    &.custom-infowindow__date {
    	// margin-bottom: 10px;
    	font-weight: 600;
    	padding-right: 20px;
    }
    span {
    	font-weight: 600;
    	color: $limestone_20;
    }
}

.custom-infowindow a {
    display: inline-block;
    margin-top: 10px;
}

/** Landscape Types Map */

.map-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
}

.map-search {
	display: flex;
	align-items: center;
	position: absolute;
	top: 10px;
	left: 200px;
	width: 200px;
	height: 40px;
	padding: 5px 10px;
	border: 0;
	box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
	z-index: 1;
	font-size: 16px;
	background-color: $white;
	border-radius: 0;
	@include respond-to-max($vpC) {
		top: 58px;
		left: 10px;
		width: 196px;
	}
}

.map-dialog {
	max-width: 280px;
	width: 100%;
	border: 1px solid black;
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: white;
	display: none;
	transform: translate(-50%, -50%);
	z-index: 2;
	padding: 20px;
}

.map-dialog.active {
	display: block;
}

.map-dialog h3 {
	font-size: rem($typeL);
	font-weight: 400;
	margin: 0 0 10px;
	color: $woodlands_10;
}

.map-dialog a {
	color: $black;
	display: block;
	position: relative;
	margin-bottom: 5px;
	font-size: rem($typeS);
	text-decoration: none;
	transition: all .5s ease-in-out;
	@include link-active-styles {
		border-color: $black;
	}
}

.map-dialog .map-dialog-close {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 5px;
	right: 5px;
	border: 0;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: transparent;
	cursor: pointer;
	overflow: hidden;
}

.map-dialog .map-dialog-close span {
	display: block;
	text-indent: -99999em;
}

 /*.map-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 9999;
		width: 100%;
		padding: 20px 0 15px;
		color: white;
		background-color: #84be63;
		text-align: center;
		font-size: 1.875rem;
		font-family: sans-serif !important;
		transform: translateY(100%);
		transition: transform .5s;
}

 .map-footer a {
		color: inherit !important;
		font-size: inherit;
		font-family: sans-serif !important;
		text-decoration: underline;
}

 .map-footer.active {
		transform: translateY(0);
}*/