@use 'sass:math';

/**
 * Galleries
 * ----------------------------------------------------------------------------
 */

.gallery {
	margin-bottom: $internal-spacing;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	@supports (display: grid) {
		display: grid;
		grid-column-gap: $internal-spacing;
		grid-row-gap: $internal-spacing;
		grid-template-columns: repeat(2, 1fr);
		@include respond-to($vpB) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include respond-to($vpC) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.gallery__item {
	overflow: hidden;
	position: relative;
	margin: 15px;
	width: 50%;
	@include respond-to($vpC) {
		width: 25%;
	}
	display:inline;
	a {
		border-bottom: 0;
	}
	img {
		width: auto;
		max-width: 100%;
	}
	@supports (display: grid) {
		margin: auto;
		width: auto;
	}
	&--padded {
		padding: $internal-spacing;
	}
}