@use 'sass:math';

.drawer {
    border-bottom: 1px solid $limestone_40;
    &:last-child {
    	border-bottom: 0;
    }
}

.drawer[open] {
    .drawer__title {
    	background-color: $limestone_50;
    }
    .drawer__title::before {
        transform: rotate(180deg);
        background-image: url(/assets/icons/source/chevron-down.svg);
        .drawer--featured & {
        	top: 28px;
        }
    }
}

.drawer__title {
    position: relative;
    @extend .heading;
    font-size: rem($typeL);
    background-color: $limestone_60;
    padding: math.div($internal-spacing,2) $internal-spacing;
    padding-right: $internal-spacing*2.5;
    transition: all, ease-in-out, 0.1s;
    cursor: pointer;
    list-style: none;
    &::-webkit-details-marker {
        display: none;
    }
    &:before {
        content: "";
        background-image: url(/assets/icons/source/chevron-down.svg);
        width: 36px;
        height: 36px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        right: 10px;
        top: 9px;
        transition: all, ease-in-out, 0.1s;
    }
    svg {
    	width: 70px;
    	height: 70px;
    	float: left;
    	margin-right: $internal-spacing;
    }
}

.drawer__content {
	display: block;
	overflow: hidden;
    padding: $internal-spacing;
    background-color: $limestone_60;
    @include respond-to($vpC) {
        padding-top: math.div($internal-spacing,2);
    }
    &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .drawer--featured & {
        	border-bottom-left-radius: 0;
	        border-bottom-right-radius: 0;
	    }
    }
}