// List
// ------------------------------------------------------------

.m-list {
    list-style: none;
    font-size: rem($typeDefault);
    margin:  $internal-spacing 0;
    &--columns {
        columns: 1;
        @include respond-to($vpB) {
            columns: 2;
        }
        @include respond-to($vpC) {
            columns: 3;
        }
    }
}

.link-list {
	list-style: none;
	li {

	}
	a {
		text-decoration: none;
		border-bottom-color: transparent;
		font-size: rem($typeM);
		display: block;
		padding: 10px 0;
		@include link-active-styles {
			font-family: $fontHeading;
			color: $grasslands_20;
		}
	}
	svg{
		width: 20px;
		height: 20px;
		color: $grasslands_20;
		position: relative;
		margin-right: 5px;
	}
}

.meta-list {
	display: inline-grid;
	grid-template-columns: auto auto;
	grid-row-gap: 5px;
	grid-column-gap: 10px;
	dt {
		font-family: $fontHeading;
		.card--route & {
			color: $limestone_20;
		}
	}
	dd {
		margin-inline-start: 0;
	}
}