@use 'sass:math';

.cta {
	background: $woodlands_40;
	position: relative;
	border-radius: $border-radius;
	overflow: hidden;
	&--image {
		@include respond-to($vpB) {
			min-height: 270px;
		}
	}
	&--green {
		background: $grasslands_40;
	}
}

.cta__heading {
	@extend .heading;
	font-size: rem($typeXL);
	margin-bottom: math.div($internal-spacing,2);
	position: relative;
	line-height: 1.3;
}

.cta__subheading {
	@extend .heading;
	font-size: rem($typeL);
	position: relative;
}

.cta__content {
	padding: $internal-spacing*1.5;
	font-size: rem($typeDefault);
	margin-bottom: math.div($internal-spacing,2);
	.cta--image & {
		@include respond-to($vpB) {
			padding-left: 290px;
		}
	}
}

.cta__image {
	display: none;
	@include respond-to($vpB) {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 270px;
		min-height: 270px;
		height: 100%;
		background-size: cover;
		border-radius: $border-radius;
	}
}