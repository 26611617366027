
// Icons pop
@-webkit-keyframes hvr-icon-pop {
  50% {
    transform: scale(1.25) translateY(-50%);
  }
}

@keyframes hvr-icon-pop {
  50% {
    transform: scale(1.25) translateY(-50%);
  }
}

// Ripples
@-webkit-keyframes hvr-ripple-out {
  0% {
    opacity: 1;
  }
  100% {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  0% {
    opacity: 1;
  }
  100% {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    opacity: 0;
  }
}

@-webkit-keyframes hvr-ripple-circle {
  0% {
    opacity: 1;
    transform: scale(1) translateX(3px) translateY(3px);
  }
  50% {
    transform: scale(1.4) translateX(3px) translateY(3px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(3px) translateY(3px);
  }
}
@keyframes hvr-ripple-out-circle {
  0% {
    opacity: 1;
    transform: scale(1) translateX(3px) translateY(3px);
  }
  50% {
    transform: scale(1.4) translateX(3px) translateY(3px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(3px) translateY(3px);
  }
}
