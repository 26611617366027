// CookieYes
// ------------------------------------------------------------


// Privacy page table
.cky-table-wrapper {
	width: 100%;
	max-width: 100%;
	overflow: auto;
}
.cky-cookie-audit-table {
	font-family: inherit;
	border-collapse: collapse;
	width: 100%;
	margin-top: $internal-spacing;
}
.cky-cookie-audit-table th {
	background-color: #ddd!important;
	border: 1px solid #ccc!important;
}
.cky-cookie-audit-table td {
	border: 1px solid #ccc;
}
.cky-cookie-audit-table th,
.cky-cookie-audit-table td {
	text-align: left;
	padding: 10px;
	font-size: 14px!important;
	color: $cText!important;
	word-break: normal;
	vertical-align: top;
}
.cky-cookie-audit-table td p {
	font-size: 14px!important;
	line-height: 24px;
	margin-bottom: 1em;
}
.cky-cookie-audit-table td p:last-child {
	margin-bottom: 0;
}
.cky-cookie-audit-table tr:nth-child(2n + 1) td {
	background: #eee!important;
}
.cky-cookie-audit-table tr:nth-child(2n) td {
	background: #ffffff!important;
}
.cky-audit-table-element h3 {
	margin: 35px 0 16px 0;
}
.cky-audit-table-element .cky-table-wrapper {
	margin-bottom: 1rem;
}
.cky-audit-table-element .cky-category-des p {
	margin-top: 0;
}
