
.section {
    position: relative;
    clear: both;
    z-index: 10;
    background-color: $white;
    @include respond-to($vpD) {
        // padding: $internal-spacing*5 0;
    }
    @include respond-to($vpE) {
        // padding: $internal-spacing*5 0;
    }
    .section__inner {
    	padding: $internal-spacing*2 $internal-spacing;
    	background-color: $white;
    	@include respond-to($vpC) {
	        padding: $internal-spacing*3 $internal-spacing;
	    }
	    &--green {
	    	background-color: $grasslands_20;
	    }
	    &--lightgreen {
	    	background-color: $grasslands_30;
	    }
	    &--purple {
	    	background-color: $moors_10;
	    }
	    &--blue {
	    	background-color: $ocean_rivers_10;
	    }
    }
    &--rounded {
    	background-color: $woodlands_10;
    	.section__inner {
    		border-top-left-radius: $border-radius*2;
    		border-top-right-radius: $border-radius*2;
    		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.10);
    	}
    }
    &--rounded--green {
    	background-color: $grasslands_20;
    }
    &--rounded--white {
    	background-color: $white;
    }
    &--rounded--woodlands {
    	background-color: $woodlands_20;
    }
    &--rounded--woodlands {
    	background-color: $woodlands_20;
    }
    &--rounded--grasslands {
    	background-color: $grasslands_10;
    }
    &--rounded--blue {
    	background-color: $ocean_rivers_40;
    }
    &--rounded--yellow {
    	background-color: $sands_40;
    }
	&--highlight {
        background-color: $white;
        .section__inner {
        	background-color: $limestone_50;
        	box-shadow: none;
        }
    }
    &--greenlight {
    	background-color: $grasslands_30;
		.section__inner {
			@include respond-to($vpC) {
		        padding: $internal-spacing*6 $internal-spacing;
		    }
		}
    }
    &--exploring {
    	.section__inner {
	    	background-image: url('/assets/img/background/landscape.svg');
	    	background-repeat: no-repeat;
	    	background-size: cover;
	    	background-position: 50% bottom;
	    	padding: $internal-spacing*5 $internal-spacing;
	    }
    }
     &--work {
    	.section__inner {
	    	background-image: url('/assets/img/background/river.svg');
	    	background-repeat: no-repeat;
	    	background-size: cover;
	    }
    }
    &--lion {
    	.section__inner {
	    	background-image: url('/assets/img/background/valley.svg');
	    	background-repeat: no-repeat;
	    	background-size: 100%;
	    	background-position: 50% bottom;
	    	padding: $internal-spacing*5 $internal-spacing;
	    }
    }
    &--cycling {
    	.section__inner {
	    	background-image: url('/assets/img/background/corn.svg');
	    	background-repeat: repeat-x;
	    	background-size: 25%;
	    	background-position: center bottom;
	    	padding: $internal-spacing*5 $internal-spacing;
	    }
    }
    &--split {
		.section__inner {
			background-color: $ocean-rivers_10;
			position: relative;
			z-index: 1;
			color: $white;
			@include respond-to($vpC) {
				&:after {
					border-top-left-radius: $border-radius*2;
					border-top-right-radius: $border-radius*2;
					content: "";
					width: 50%;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					background-color: $grasslands_10;
					z-index: 1;
				}
			}
			.grid__col {
				position: relative;
				z-index: 2;
			}
		}
	}
	&--split--grasslands {
		.section__inner {
			background-color: $grasslands_40;
			color: $white;
			position: relative;
			// @include respond-to-max($vpC) {
			// 	&:after {
			// 		border-top-left-radius: $border-radius*2;
			// 		border-top-right-radius: $border-radius*2;
			// 		content: "";
			// 		width: 100%;
			// 		position: absolute;
			// 		top: 0;
			// 		right: 0;
			// 		bottom: 0;
			// 		background-color: $grasslands_10;
			// 		z-index: 1;
			// 	}
			// }
		}
	}
	&--split--grasslands-dark {
		.section__inner {
			background-color: $grasslands_10;
			@include respond-to($vpC) {
				&:after {
					background-color: $grasslands_40;
				}
			}
		}
	}
	&--visitorinfo {
		.section__inner {
			.grid__col:first-of-type {
				@include respond-to($vpC) {
					position: relative;
					.visitor-info {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
	&--rounded--route {
		background-color: $woodlands_20;
		.section__inner {
			background-color: $limestone_60;
			padding: $internal-spacing;
		}
	}
	// &--split-reverse {
	// 	background-color: $skies_20;
	// 	@include respond-to($vpC) {
	// 		&:after {
	// 			background-color: $skies_40;
	// 		}
	// 	}
	// }
	// &--minor {
    //     padding: $internal-spacing 0;
    //     @include respond-to($vpC) {
    //     	padding: $internal-spacing*2 0;
    //     }
    // }
}

.section__intro {
    text-align: center;
    margin-bottom: $internal-spacing*2;
}

.section__heading {
    @extend .heading;
    line-height: 1.3;
    font-size: rem($typeXXLL);
    margin-bottom: $internal-spacing;
    position: relative;
    z-index: 99;
    scroll-margin-top: 200px;
    overflow: initial;
    color: $black;
    .section--minor & {
    	margin-bottom: $internal-spacing;
    }
    &--centred {
        text-align: center;
    }
    &--large {
    	font-size: rem($typeXXLL);
    }
    .section--rounded--white &,
    .section--split &,
    .section__inner--blue & {
    	color: $white;
    }
    .section--split--grasslands & {
    	color: $black;
    }
    .section--split & {
    	// margin-top: 200px;
    }
    .section--work &,
    .section--lion &,
    .section--exploring & {
    	font-size: rem($typeXXLL);
    }
}

.section__heading__link {
    display: block;
    clear: left;
    margin-bottom: $internal-spacing;
    .button {
    	margin-top: $internal-spacing*.5;
    	margin-bottom: $internal-spacing;
    	float: left;
    }
    @include respond-to($vpBB) {
        float:right;
        .button {
        	float: none;
        }
    }
}

.section__subheading {
    @extend .heading;
    font-size: rem($typeL);
    margin-bottom: $internal-spacing*2;
    p {
    	@extend .text;
		margin-top: rem($typeDefault);
		display: block;
    }
}

.section__copy  {
    font-size: rem($typeDefault);
    z-index: 2;
    position: relative;
    .section--split--grasslands & {
    	color: $black;
    }
}

.section__centred {
	padding: $internal-spacing;
	text-align: center;
}