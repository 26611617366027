@use 'sass:math';

// Button defaults
// ------------------------------------------------------------------

// Button base style
%button {
	background-image: none;
	border-radius: $btnRadius;
	border: 1px solid $sands_20;
	color: $black;
	cursor: pointer;
	display: inline-block;
	font-family: $fontHeading;
	padding: 0 $btnPadding;
	position: relative;
	text-align: center;
	text-align: left;
	text-decoration: none;
	transition: all, ease-in-out, 0.3s;
	vertical-align: middle;
	span {
		font-size: inherit;
		font-weight: inherit;
	}
	&:focus {
		outline: none;
	}
}

// Sizes
// ------------------------------------------------------------------

.button,
a.button,
button.button {
	@extend %button;
	font-size: rem($typeMe);
	line-height: $btnDefault;
	background-color: $cButtonPrimary;
	transition-duration: 0.1s;
	color: $black;
	svg {
		color: $black;
	}
	@include link-active-styles {
		text-decoration: none;
		// transition-duration: 0.1s;
	}
	&--small {
		font-size: rem($typeS);
		line-height: $btnSmall;
		padding: 0 $btnPaddingSmall;
	}
	&--icon {
		padding-right: $btnPadding*3;
		text-align: left;
		svg {
			position: absolute;
			top: 50%;
			right: $btnPadding*.8;
			transform: translateY(-50%);
			transition-duration: 0.3s;
			transition-property: transform;
			transition-timing-function: ease-out;
			transform-origin: top;
			height: 18px;
			width: 20px;
			display: block;
			color: $white;
		}
		@include link-active-styles {
			svg {
				animation-name: hvr-icon-pop;
				animation-duration: 0.3s;
				animation-timing-function: linear;
				animation-iteration-count: 1;
			}
		}
	}
	// Types
	&--primary {
		background: $sands_20;
		border-color: $sands_10;
		svg {
			color: $black;
		}
		@include link-active-styles {
			background: $sands_30;
		}
	}
	&--secondary {
		background-color: transparent;
		border-color: $sands_10;
		color: $black;
		svg {
			color: $black;
		}
		@include link-active-styles {
			background-color: $sands_40;
			border-color: $sands_20;
		}
	}
	&--tertiary {
		background-color: transparent;
		border-color: $sands_30;
		color: $white;
		svg {
			color: $white;
		}
		@include link-active-styles {
			background-color: $sands_40;
			border-color: $sands_20;
			color: $black;
			svg {
				color: $black;
			}
		}
	}
	&--quarternary {
		background: $sands_20;
		font-size: rem($typeM);
		border-bottom-right-radius: 50px;
		border-top-right-radius: 50px;
		padding: $internal-spacing*.5 $internal-spacing*.5 $internal-spacing*.5 $internal-spacing;
		svg {
			transition-duration: 0.3s;
			transition-property: transform;
			transition-timing-function: ease-out;
			transform-origin: top;
			height: 40px;
			width: 40px;
			display: block;
			float: right;
			margin-left: 10px;
		}
		@include link-active-styles {
			background: $sands_30;
		}
	}
	&--quinary {
		background: transparent;
		font-size: rem($typeM);
		font-family: $fontDefault;
		padding: 0;
		border: 0;
		border-radius: 0;
		border-bottom: 2px solid $grasslands_30;
		svg {
			height: 24px;
			width: 24px;
			display: block;
			float: left;
			margin-right: 10px;
			position: relative;
			color: $grasslands_20;
			top: 4px;
		}
		@include link-active-styles {
			border-color: $grasslands_20;
		}
	}
	&--senary {
		background-color: transparent;
		border-color: $grasslands_30;
		border-width: 2px;
		color: $black;
		border-radius: 22px;
		// font-family: $fontDefault;
		font-size: rem($typeL);
		line-height: 1.5;
		padding: 5px 15px;
		@include link-active-styles {
			background-color: $moors_40;
			border-color: $moors_20;
		}
	}
	&--district {
		background-color: transparent;
		border-color: $moors_30;
		color: $black;
		border-radius: 22px;
		font-family: $fontDefault;
		font-size: rem($typeL);
		line-height: 1.5;
		padding: 5px 15px;
		&.button--icon {
			padding-right: 45px;
		}
		svg {
			color: $grasslands_20;
			height: 24px;
			width: 24px;
		}
		@include link-active-styles {
			background-color: $moors_40;
			border-color: $moors_20;
		}
	}
	&--a11y {
		position: relative;
		overflow: hidden;
		span {
			top: -9999em;
			position: absolute;
			display: inline-block;
		}
		&:after {
			content: attr(title);
			display: inline-block;
			color: inherit;
		}
	}
	&--disabled {
		cursor: not-allowed;
		background-color: $limestone_30;
		border-color: $limestone_20;
		color: #fff;
		&:focus {
			outline: none;
			text-decoration: none;
		}
		@include link-active-styles {
			text-decoration: none;
		}
	}
	&--block {
		display: block;
		width: 100%;
		text-align: center;
	}
	&--right {
		clear: left;
		margin-top: $internal-spacing;
		@include respond-to($vpB) {
			clear: none;
			margin-top: 0;
			float: right;
		}
	}
}

.button-group {
	.button {
		margin-right: 10px;
		margin-top: 10px;
	}
	&--spaced {
		margin-bottom: $internal-spacing*2;
	}
}

.button-text {
	font-size: rem($typeS);
	color: $black;
	text-decoration: underline;
	cursor: pointer;
}

.button-info {
	width: 22px;
	height: 22px;
	background-color: $grasslands_30;
	line-height: 1;
	text-align: center;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	top: -2px;
	margin-left: 3px;
	span {
		@include a11yhide;
	}
	svg {
		width: 4px;
		height: 15px;
		color: $woodlands_10;
	}
	.modal__header & {
		margin-left: 0;
		margin-right: 5px;
	}
	@include link-active-styles {
		background-color: darken($grasslands_30,10%);
	}
}