// Flickity
// https://unpkg.com/flickity@2.2.2/dist/flickity.css
/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
	position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: $white;
  border: none;
  color: $grasslands_20;
}

.flickity-button:hover {
	background: white;
	cursor: pointer;
}

.flickity-button:focus {
	outline: none;
	box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
	opacity: 0.6;
}

.flickity-button:disabled {
	opacity: .5;
	cursor: auto;
	pointer-events: none;
}

.flickity-button-icon {
	fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous { left: 16px; }
.flickity-prev-next-button.next { right: 16px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -15px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  transition: all, ease-in-out, 0.3s;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
	display: inline-block;
	width: 8px;
	height: 8px;
	margin: 0 6px;
	background: $limestone_20;
	border-radius: 10px;
	opacity: 0.25;
	cursor: pointer;
	transition: all, ease-in-out, 0.3s;
}

.flickity-page-dots:hover {
	transform: translateY(50%);
}

.flickity-page-dots:hover .dot {
	width: 20px;
	height: 20px;
}

.flickity-page-dots .dot.is-selected {
	opacity: 1;
	width: 30px;
	background-color: $grasslands_20;
}

.flickity-page-dots:hover .dot.is-selected {
	width: 40px;
}

//Flickity overrides and additions

.flickity-viewport {
	overflow: visible;
}

.carousel {
	clear: both;
	&.flickity-enabled {
		padding-bottom: $internal-spacing*2.5;
	}
}

.flickity-page-dots {
	bottom: 15px;
}


.carousel--triple {

	&.flickity-enabled {
		padding-top: $internal-spacing*2;
	}

	.flickity-prev-next-button {
		top: 0;
		border: 1px solid $skies_40;
	}

	.flickity-prev-next-button.previous {
		left: auto;
		right: 60px;
	}
	.flickity-prev-next-button.next {
		left: auto;
		right: 0;
	}

	.flickity-prev-next-button .flickity-button-icon {
		position: absolute;
		left: 20%;
		top: 20%;
		width: 60%;
		height: 60%;
	}

	.flickity-page-dots {
		width: auto;
		position: absolute;
		bottom: auto;
		top: 0;
		right: 120px;
		@include respond-to($vpC) {
			bottom: -25px;
			margin-left: -$internal-spacing;
		}
		@include respond-to($vpD) {
			margin-left: 0;
		}
	}
}

.carousel--single {
	overflow: hidden;
	.flickity-prev-next-button {
		top: 47.5%;
	}
}


.flickity-enabled {
	&.grid-3up {
		display: block;
		.carousel__item {
			width: 100%;
			margin-right: 0;
		}
		&.carousel--triple {
			.carousel__item {
				margin-right: 4%;
				@include respond-to($vpB) {
					width: 48%;
					margin-right: 2%;
				}
				@include respond-to($vpC) {
					width: 32%;
				}
			}
		}
	}
}

.carousel__item {
	.flickity-enabled & {
		position: relative;
		background-size: cover;
		background-position: center;
	}
	.carousel--single.flickity-enabled & {
		@include ratio(4 3);
	}
	&__caption {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-right: $internal-spacing*3;
		background-color: $white;
	    padding: 5px 10px;
	    font-size: rem($typeMe);
	    line-height: 1.5;
	    color: $limestone_20;
	    width: auto;
	    p {
	    	margin-top: 0;
	        font-size: inherit;
	    }
	    a {

	    }
	}
}