
.row {
	@include pie-clearfix;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	min-width: auto;
	max-width: $gridMax;
	&--static {
		position: static;
	}
	&--thinner {
		max-width: calc($gridMax - 200px);
	}
	&--thinnest {
		max-width: calc($gridMax - 400px);
	}
	@include respond-to($vpC) {
		// padding-left: $internal-spacing;
		// padding-right: $internal-spacing;
		.header-wrap & {
			// padding-left: 0;
			// padding-right: 0;
		}
	}
	@include respond-to-min-max($vpC,$vpD) {
		.header-wrap & {
			// padding-left: 0;
			// padding-right: 0;
		}
	}
	@include respond-to($vpMenu) {
		.header-wrap & {
			// padding-left: $internal-spacing;
			// padding-right: $internal-spacing;
		}
	}
	@include respond-to($vpF) {
	    // padding-left: 0;
	    // padding-right: 0;
	}
}
