@use 'sass:math';

.aside {
	margin-bottom: $internal-spacing*2;
	background-color: $sands_40;
	border-radius: $border-radius;
	&--blue {
		background: $ocean_rivers_40;
	}
	&--weather {
		background-color: $white;
		border: 1px solid $limestone_40;
	}
}


.aside__heading {
	@extend .heading;
	font-size: rem($typeL);
}

.aside__section-heading {
	@extend .heading;
	line-height: 1.3;
	font-size: rem($typeXL);
	margin-bottom: $internal-spacing;
	position: relative;
}

.aside__text {
	font-size: rem($typeDefault);
	margin: $internal-spacing*.75 0 $internal-spacing*1.5;
}

.aside__content {
	font-size: rem($typeDefault);
	margin-bottom: math.div($internal-spacing,2);
	padding: $internal-spacing $internal-spacing*1.5 $internal-spacing*1.5 $internal-spacing*1.5;
	p {
		margin-bottom: rem($typeDefault);
		display: block;
		&:last-child {
			margin-bottom: 0;
		}
	}
	a:not(.button) {
		font-size: rem($typeL);
		@include link-active-styles {
			color: $sands_10;
		}
	}
}

.aside__list {
	list-style: none;
	font-size: rem($typeDefault);
	li {
		display: block;
		font-size: rem($typeMe);
		margin-bottom: $internal-spacing;
	}
}