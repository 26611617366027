// // ------------------------------------------------------------
// // Contact form 7

.wpcf7 form .wpcf7-response-output {
	margin: 0;
    padding: $internal-spacing*.5 $internal-spacing;
	position: relative;
	background-color: $heathlands_20;
	border: 2px solid $heathlands_30;
	color: $cHeading;
	font-size: rem($typeS);
	border-radius: $border-radius;
	p, ul {
		margin-bottom: 10px;
	}
	ul {
		list-style-position: inside;
		padding-left: $internal-spacing;
	}
}

.wpcf7 form.init .wpcf7-response-output {
    display: none;
}

.wpcf7 form.sent .wpcf7-response-output {
    border-color: $heathlands_30;
}

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
    border-color: $cError;
    background-color: $rocky_20;
}

.wpcf7 form.spam .wpcf7-response-output {
    border-color: $cError;
    background-color: $rocky_20;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
    border-color: $cError;
    background-color: $rocky_20;
}

.wpcf7-not-valid,
.form-item.wpcf7-not-valid {
	border-color: $cError;
	background-color: $rocky_20;
}

.wpcf7-not-valid-tip {
    color: $cError;
    font-size: rem($typeS);
    font-weight: normal;
    display: block;
}

.screen-reader-response {
  display: none;
}