@use 'sass:math';

.hero {
	position: relative;
	padding: $internal-spacing*2 $internal-spacing;
	@include respond-to($vpC) {
		padding: $internal-spacing*4 $internal-spacing;
	}
	.card {
		z-index: 5;
	}
	&--contact {
		padding: 0;
		min-height: 450px;
	}
	&--home {
		padding: $internal-spacing $internal-spacing;
		@include respond-to($vpDD) {
			padding: $internal-spacing*2 $internal-spacing;
		}
		@include respond-to($vpF) {
			padding: $internal-spacing*4 $internal-spacing;
		}
	}
}

.hero__link {
	font-size: rem($typeMe);
	border-bottom: 0;
	svg {
		width: 20px;
		margin-left: $internal-spacing*.5;
	}
	@include link-active-styles {
		border-bottom: 2px solid $heathlands_40;
	}
}

.hero__caption {
	max-width: 800px;
	z-index: 6;
	position: relative;
	.hero--centered & {
		margin: 0 auto;
		text-align: center;
	}
	.hero__description,
	.hero__meta {
		+ .button {
			margin-top: $internal-spacing;
		}
	}
	.button {
		position: relative;
		left: 0;
		top: 0;
	}
	.hero--home &,
	.hero--image &,
	.hero--contact & {
		z-index: 2;
		text-align: left;
		color: $heathlands_40;
		margin: 0;
		@include respond-to($vpC) {
			width: 40%;
			float: left;
			margin-bottom: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		.typebase {
			color: $heathlands_40;
		}
	}
}

.hero__aside {
	position: relative;
	z-index: 5;
	@include respond-to($vpC) {
		width: 55%;
		float: right;
		// .grid_col & {
		// 	width: 100%;
		// }
	}
	.hero--image & {
		margin-top: $internal-spacing*2;
		@include respond-to ($vpC) {
			margin-top: 0;
		}
	}
}

.hero__aside__content {
	padding: $internal-spacing*2;
}

.hero__media {
	overflow: hidden;
	position: relative;
	// @include ratio(16 9);
	// background-size: cover;
	// background-position: center;
	// border-radius: $border-radius;
	.hero--route & {
		padding-bottom: 0;
	}
	img {
		width: 100%;
		max-width: 100%;
		border-radius: $border-radius;
		.hero--route & {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	object,embed,iframe  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.hero--article & {
		margin-top: $internal-spacing;
	}
}

.hero__media__caption,
.hero__aside__caption {
	color: $white;
	font-size: rem($typeXS);
	float: left;
	padding: 5px 0;
	a {
		color: $white;
		border-color: $grasslands_40;
		@include link-active-styles {
			border-color: $grasslands_30;
		}
	}
	.hero--self-guided-route & {
		position: absolute;
		bottom: 37px;
		left: 0;
		background-color: $woodlands_10;
		padding: 5px 12px;
		border-top-right-radius: $border-radius;
	}
}

.hero__media__caption {
	position: absolute;
	bottom: 0;
	left: 0;
	float: none;
	z-index: 1;
}

.hero__heading {
	@extend .heading;
	color: $white;
	font-size: rem($typeXXL);

	line-height: 1.2;
	margin-bottom: $internal-spacing;
	@include respond-to($vpD) {
		font-size: rem($typeXXLLL);
	}
	a {
		border: 0;
		@include link-active-styles {
			border-bottom: 2px solid $heathlands_40;
		}
	}
	.hero--exploring &,
	.hero--wardenhome & {
		color: $black;
	}
}

.hero__subheading {
	@extend .heading;
	color: $white;
	font-size: rem($typeL);
	line-height: 1.2;
	margin-bottom: $internal-spacing;
	.hero--contact & {
		line-height: 1.6;
	}
	.hero--exploring & {
		color: $black;
	}
}

.hero__description {
	font-size: rem($typeL);
	color: $white;
	.hero--exploring &,
	.hero--wardenhome & {
		color: $black;
	}
	p a {
		color: $white;
		border-bottom-color: $grasslands_20;
		@include link-active-styles {
			border-bottom-color: $grasslands_30;
		}
	}
}

.hero__meta {
	list-style: none;
	font-size: rem($typeM);
	margin: math.div($internal-spacing,1.5) 0 math.div($internal-spacing,2) 0;
	color: $white;
	.hero--event & {
		display: inline-grid;
		grid-template-columns: auto auto;
		grid-row-gap: 5px;
		grid-column-gap: 10px;
	}
	dt {
		font-family: $fontHeading;
	}
	dd {
		margin-inline-start: 0;
	}
	li {
		margin-right: math.div($internal-spacing,2);
		svg {
			width: 20px;
			height: 20px;
			margin-right: 10px;
			display: inline-block;
			stroke: $white;
			stroke-width: 1.5px
		}
		a {
			text-decoration: none;
			border-bottom: transparent;
			@include link-active-styles {
				border-bottom: 1px solid $white;
			}
		}
	}
}

// Icon for signpost in routes listing page

.hero__icon {
	position: absolute;
	bottom: 0;
	width: 80px;
	right: $internal-spacing*.5;
	@include respond-to($vpB) {
		width: 120px;
		// right: 8%;
	}
	@include respond-to($vpC) {
		width: 200px;
		right: 8%;
	}
	.header-wrap--cycling &,
	.header-wrap--horse-riding & {
		right: $internal-spacing;
	}
}

// Hero aside list for route links

.hero__aside__list {
	background-color: $grasslands_10;
	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
	width: 100%;
	list-style: none;
	display: flex;
	align-items: stretch;
	flex-wrap: nowrap;
	gap: 5px;
	overflow: hidden;
	&__item {
		flex: 1 1 auto;
		display: inline-block;
		a {
			font-size: rem($typeS);
			color: $white;
			display: block;
			padding: 5px 0;
			border-bottom: 0;
			text-align: center;
			svg {
				height: 20px;
				width: 20px;
				color: $grasslands_30;
				position: relative;
				top: -3px;
				margin-right: 2px;
			}
			@include link-active-styles {
				background-color: $grasslands_20;
			}
		}
	}
}

// Hero list of buttons for wardens home

.hero__inline-list {
	margin-top: $internal-spacing*2;
	list-style: none;
	display: flex;
	gap: $internal-spacing;
	align-items: center;
  	justify-content: center;
  	flex-wrap: wrap;
	li {
		display: inline-block;
	}
}

// Search form in hero for search page

.hero__search {
	position: relative;
	padding-right: $internal-spacing;
	label {
		@include a11yhide;
	}
	input {
		border-color: #fff;
		font-size: rem($typeM);
	}
	button {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		line-height: 1;
		height: 40px;
		width: 40px;
		background-color: $grasslands_20;
		border-color: $grasslands_20;
		span {
			top: -9999em;
			position: absolute;
			display: inline-block;
		}
		svg {
			margin: 18px 0 0 9px;
			transform: translateY(-50%);
			transition-duration: 0.3s;
			transition-property: transform;
			transition-timing-function: ease-out;
			transform-origin: top;
			height: 22px;
			width: 22px;
			display: block;
			stroke: $white;
			stroke-width: 1.5px;
		}
		@include link-active-styles {
			svg {
				animation-name: hvr-icon-pop;
				animation-duration: 0.3s;
				animation-timing-function: linear;
				animation-iteration-count: 1;
			}
		}
	}
}

// Hero grids

// Home

.home-hero-grid {
	height: 45vw;
	max-height: 600px;
	position: relative;
	.home-hero-grid__heading {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		color: white;
		z-index: 10;
		font-family: $fontHeading;
		text-align: center;
		margin: 0 auto;
		line-height: 1.4;
		font-size: rem($typeXXL);
		@include respond-to($vpC) {
			font-size: rem($typeXXLL);
		}
		@include respond-to($vpD) {
			font-size: rem($typeXXXL);
		}
	}
	&__media {
		transition: transform 60ms;
		position: absolute;
		z-index: 5;
		border-radius: $border-radius;
		overflow: hidden;
		img {
			width: 100%;
			max-width: 100%;
			border-radius: $border-radius;
		}
	}
	&__patch {
		position: absolute;
		z-index: 5;
		&__inner {
			border-radius: $border-radius;
		}
	}
	&__media {
		z-index: 6;
	}
	&__media-1 { // Flower video
		top: -3vw;
		left: 0;
		width: 34%;
		@include respond-to-max($vpDD){
			display: none;
		}
	}
	&__media-2 { // people
		top: -7vw;
		right: -3vw;
		width: 39%;
		@include respond-to-max($vpB){
			display: none;
		}
		@include respond-to-min-max($vpB, $vpDD){
			top: 0;
			right: 0;
			width: 35%;
		}
	}
	&__media-3 { // Flower image
		bottom: -8vw;
		right: 24%;
		width: 17%;
		@include respond-to-max($vpDD){
			display: none;
		}
	}
	&__media-4 { // Trampers
		bottom: 0;
		left: 10%;
		width: 22vw;
		@include respond-to-max($vpB){
			display: none;
		}
		@include respond-to-min-max($vpB, $vpDD){
			left: 0;
		}
		@include respond-to($vpF) {
			width: 22%;
		}
	}
	&__patch-1 { // Corn
		top: 4vw;
		left: 40%;
		width: 17%;
		@include respond-to-max($vpB){
			top: 0;
			width: 12%;
			left: auto;
			right: 0;
		}
		@include respond-to-min-max($vpB, $vpDD){
			left: auto;
			top: 0;
			right: 38%;
		}
		.home-hero-grid__patch__inner {
			width: 240px;
			height: 120px;
			background-color: $woodlands_40;
			background-image: url('/assets/img/patches/corn-2.svg');
			background-size: 100%;
			background-position: center bottom;
			float: right;
			@include respond-to-max($vpC) {
				width: 80px;
				height: 80px;
				background-size: 200%;
			}
			@include respond-to-min-max($vpC, $vpDD) {
				width: 160px;
				height: 80px;
			}
		}
	}
	&__patch-2 { // River
		bottom: 10%;
		right: 6%;
		width: 9%;
		@include respond-to-max($vpDD){
			bottom: 0;
			right: 0;
		}
		.home-hero-grid__patch__inner {
			width: 120px;
			height: 240px;
			background-color: $skies_40;
			background-image: url('/assets/img/patches/river.svg');
			background-size: cover;
			float: right;
			@include respond-to-max($vpC) {
				height: 80px;
				width: 80px;
			}
			@include respond-to-min-max($vpC, $vpDD) {
				width: 80px;
				height: 160px;
			}
		}
	}
	&__patch-3 { // Stones
		bottom: 38%;
		left: 4%;
		width: 9%;
		@include respond-to-max($vpDD){
			bottom: auto;
			top: 0;
			left: 0;
		}
		@include respond-to($vpF) {
			bottom: 30%;
			left: 0;
		}
		.home-hero-grid__patch__inner {
			width: 120px;
			height: 120px;
			background-color: $grasslands_30;
			background-image: url('/assets/img/patches/stones-green.svg');
			background-size: 90%;
			background-position: center;
			@include respond-to-max($vpC){
				width: 80px;
				height: 80px;
			}
		}
	}
	&__patch-4 { // Sign
		bottom: 2%;
		left: 38%;
		width: 9%;
		@include respond-to-max($vpC){
			left: 0;
			bottom: 0;
		}
		@include respond-to-min-max($vpB, $vpDD){
			left: 25%;
			bottom: 0;
		}
		.home-hero-grid__patch__inner {
			width: 120px;
			height: 120px;
			background-color: $sands_20;
			background-image: url('/assets/img/patches/signpost-2.svg');
			background-size: 80%;
			background-position: bottom center;
			@include respond-to-max($vpC){
				width: 80px;
				height: 80px;
			}
		}
	}
}

.hero-section {
	display: grid;
	@include gridCalcRepeat(1);
	@include respond-to-min-max($vpA,$vpC) {
		// height: 500px;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(1, 1fr);
  		grid-gap: $internal-spacing;
		grid-template-areas:
		"c c c c c a";
	}
	@include respond-to($vpC) {
		height: 500px;
		grid-template-columns: repeat(16, 1fr);
		grid-template-rows: repeat(7, 1fr);
  		grid-gap: $internal-spacing*2;
		grid-template-areas:
		". . . . . a a b b b b b b b b ."
		". . . . . a a b b b b b b b b ."
		". . . . . . . b b b b b b b b ."
		". c c c c c c b b b b b b b b ."
		". c c c c c c b b b b b b b b ."
		". c c c c c c d d . . . . . . ."
		". c c c c c c d d . . . . . . .";
	}
	div {
		border-radius: $border-radius;
	}
	&__intro {
		grid-area: c;
		&__inner{
			padding: $internal-spacing;
			background-color: $grasslands_40;
		}
	}
	&__heading {
		color: $grasslands_10;
		font-size: rem($typeXXXL);
		line-height: 1.2;
		font-family: $fontHeading;
		margin-bottom: 10px;
	}
	&__description {
		color: $grasslands_10;
		font-size: rem($typeM);
	}
	&__media {
		display: none;
		@include respond-to($vpC) {
			display: inline-block;
		}
		grid-area: b;
		background-size: cover;
		background-position: center;
		position: relative;
		&__caption {
			position: absolute;
			bottom: -35px;
			left: 0;
			float: none;
			z-index: 1;
			color: $white;
			font-size: rem($typeXS);
			padding: 5px 0;
			a {
				color: $white;
				border-color: $grasslands_40;
				@include link-active-styles {
					border-color: $grasslands_30;
				}
			}
		}
	}
	&__patch {
		&__inner {
			border-radius: $border-radius;
			width: 120px;
			height: 120px;
		}
	}
	&__patch-1 {
		grid-area: a;
		.hero-section__patch__inner {
			float: right;
			display: flex;
      		justify-content: center;
      		background-color: $grasslands_10;
      		background-image: url('/assets/img/patches/valley.svg');
      		background-size: 100%;
      		background-position: bottom;
      		padding-top: 20px;
      		background-repeat: no-repeat;
      		.hero--about-cotswolds & {
      			background-image: url('/assets/img/patches/stones-2.svg');
      			background-size: 80%;
      			background-position: center;
      			padding-top: 0;
      		}
      		.hero--our-work & {
      			background-color: $grasslands_30;
      			background-image: url('/assets/img/patches/plant.svg');
      			background-size: 52%;
      		}
      		.hero--about-us & {
      			background-image: url('/assets/img/patches/hills.svg');
      		}
      		.hero--get-involved & {
      			background-image: url('/assets/img/patches/oak-leaves.svg');
      			background-color: $grasslands_30;
      			background-size: 80%;
      			background-position: center;
      		}
		}
	}
	&__patch-2 {
		display: none;
		@include respond-to($vpC) {
			display: inline-block;
		}
		grid-area: d;
		.hero-section__patch__inner {
			display: flex;
      		justify-content: center;
      		background-color: $grasslands_30;
      		background-image: url('/assets/img/patches/signpost-3.svg');
      		background-size: 80%;
      		background-position: center bottom;
      		background-repeat: no-repeat;
      		.hero--about-cotswolds & {
      			background-image: url('/assets/img/patches/corn-3.svg');
      			background-position: right bottom;
      		}
      		.hero--our-work & {
      			background-image: url('/assets/img/patches/wall-2.svg');
      			background-size: 102%;
      		}
      		.hero--about-us & {
      			background-image: url('/assets/img/patches/flower.svg');
      		}
      		.hero--get-involved & {
      			background-image: url('/assets/img/patches/wall-3.svg');
      			background-color: $grasslands_20;
      			background-size: 100%;
      		}
      	}
	}
	.hero--about-cotswolds &{
		@include respond-to($vpC) {
			grid-template-areas:
				". c c c c c c d d . . . . . . ."
				". c c c c c c d d . . . . . . ."
				". c c c c c c b b b b b b b b ."
				". c c c c c c b b b b b b b b ."
				". . . . . a a b b b b b b b b ."
				". . . . . a a b b b b b b b b ."
				". . . . . . . b b b b b b b b .";
		}
	}
	.hero--our-work &{
		@include respond-to($vpC) {
			grid-template-areas:
				". . . . . . . d d c c c c c c ."
				". . . . . . . d d c c c c c c ."
				". b b b b b b b b c c c c c c ."
				". b b b b b b b b c c c c c c ."
				". b b b b b b b b a a . . . . ."
				". b b b b b b b b a a . . . . ."
				". b b b b b b b b . . . . . . .";
		}
	}
	.hero--about-us &{
		@include respond-to($vpC) {
			grid-template-areas:
				". b b b b b b b b . . . . . . ."
				". b b b b b b b b a a . . . . ."
				". b b b b b b b b a a . . . . ."
				". b b b b b b b b c c c c c c ."
				". b b b b b b b b c c c c c c ."
				". . . . . . . d d c c c c c c ."
				". . . . . . . d d c c c c c c ."
				;
		}
	}
}
